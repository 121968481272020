import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  CircularProgress,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Work as WorkIcon, Business as BusinessIcon, AttachMoney as AttachMoneyIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { useClients } from '../contexts/ClientContext';
import { useProjects } from '../contexts/ProjectContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useUser } from '../contexts/UserContext';
import moment from 'moment-timezone';

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  height: '70vh', // Set fixed height
  '& .MuiTableContainer-root': {
    maxHeight: '100%', // Ensure the inner TableContainer takes full height
    overflow: 'auto', // Enable scrolling
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

function ManageProjects() {
  const { clients } = useClients();
  const { retainers } = useRetainers();
  const { projects, loading, error, fetchProjects, addProject, updateProject, deleteProject } = useProjects();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();
  const isAdmin = user && user.permission === 'admin';

  const [openDialog, setOpenDialog] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [clientId, setClientId] = useState('');
  const [budget, setBudget] = useState('');
  const [description, setDescription] = useState('');
  const [retainerId, setRetainerId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingProject, setDeletingProject] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [projectType, setProjectType] = useState('one-off');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Update this function to handle decimals correctly
  const formatBudgetInput = (value) => {
    // Remove any non-digit characters except for the decimal point
    const cleanedValue = value.replace(/[^\d.]/g, '');
    
    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = cleanedValue.split('.');
    
    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    // Combine the formatted integer part with the decimal part (if it exists)
    return decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const sortProjects = (projects) => {
    return projects.sort((a, b) => {
      if (a.status === 'active' && b.status === 'inactive') return -1;
      if (a.status === 'inactive' && b.status === 'active') return 1;
      if (a.startDate && b.startDate) {
        return new Date(b.startDate) - new Date(a.startDate);
      }
      return a.name.localeCompare(b.name);
    });
  };

  const allProjects = useMemo(() => sortProjects(projects.filter(project => !project.isDeleted)), [projects]);

  const filteredProjects = useMemo(() => {
    return allProjects.filter(project => {
      const clientName = clients.find(c => c.id === project.clientId)?.name || '';
      const retainer = retainers.find(r => r.id === project.retainerId);
      const retainerName = retainer ? retainer.retainerName : '';
      const searchString = `${project.name} ${clientName} ${retainerName}`.toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });
  }, [allProjects, clients, retainers, searchTerm]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  const formatDisplayDate = (dateString) => {
    if (!dateString) return 'Not set';
    return parseAsUTC(dateString).format('MMM D, YYYY');
  };

  const formatInputDate = (dateString) => {
    if (!dateString) return '';
    return parseAsUTC(dateString).format('YYYY-MM-DD');
  };

  const parseAsUTC = (dateString) => {
    return moment.utc(dateString).utc(true);
  };

  const parseInputDate = (dateString) => {
    return dateString ? moment.utc(dateString).utc(true).format() : null;
  };

  const handleOpenDialog = (project = null, type = 'one-off') => {
    if (project) {
      setEditingProject(project);
      setProjectName(project.name);
      setClientId(project.clientId);
      setBudget(project.budget ? formatBudgetInput(project.budget.toString()) : '');
      setDescription(project.description || '');
      setRetainerId(project.retainerId || '');
      setStartDate(formatInputDate(project.startDate));
      setEndDate(formatInputDate(project.endDate));
      setProjectType(project.retainerId ? 'retainer' : 'one-off');
    } else {
      setEditingProject(null);
      setProjectName('');
      setClientId('');
      setBudget('');
      setDescription('');
      setRetainerId('');
      setStartDate('');
      setEndDate('');
      setProjectType(type);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingProject(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const projectData = {
        name: projectName,
        clientId,
        description,
        retainerId: projectType === 'retainer' ? retainerId : null,
        budget: projectType === 'one-off' ? (budget ? parseFloat(budget.replace(/,/g, '')) : 0) : null,
        startDate: parseInputDate(startDate),
        endDate: parseInputDate(endDate),
        projectType,
      };
      console.log('Submitting project data:', projectData);

      if (editingProject) {
        const updatedProject = await updateProject(editingProject.id, projectData);
        console.log('Project updated:', updatedProject);
        enqueueSnackbar('Project updated successfully', { variant: 'success' });
      } else {
        const newProject = await addProject(projectData);
        console.log('New project added:', newProject);
        enqueueSnackbar('Project added successfully', { variant: 'success' });
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error submitting project:', error);
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDeleteDialog = (project) => {
    setDeletingProject(project);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeletingProject(null);
  };

  const handleDeleteProject = async () => {
    try {
      await deleteProject(deletingProject.id);
      enqueueSnackbar('Project archived successfully', { variant: 'success' });
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error archiving project:', error);
      enqueueSnackbar('Failed to archive project. Please try again.', { variant: 'error' });
    }
  };

  const handleStatusChange = async (project, newStatus) => {
    try {
      console.log('Updating project status:', { projectId: project.id, newStatus });
      // Ensure the budget remains unchanged
      const updatedProject = { ...project, status: newStatus };
      if (updatedProject.budget) {
        updatedProject.budget = parseFloat(updatedProject.budget.toString().replace(/,/g, ''));
      }
      await updateProject(project.id, updatedProject);
      enqueueSnackbar(`Project status updated to ${newStatus}`, { variant: 'success' });
    } catch (error) {
      console.error('Error updating project status:', error);
      console.error('Error details:', error.response?.data);
      enqueueSnackbar('Failed to update project status. Please try again.', { variant: 'error' });
    }
  };

  const isPastDue = (project) => {
    return project.status === 'active' && project.endDate && moment.utc().isAfter(parseAsUTC(project.endDate));
  };

  const formatCurrency = (amount) => {
    if (!amount) return 'N/A';
    return `$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handleRestoreProject = async (project) => {
    try {
      const restoredProject = { ...project, isDeleted: false, status: 'inactive' };
      await updateProject(project.id, restoredProject);
      enqueueSnackbar(`Project "${project.name}" restored successfully`, { variant: 'success' });
      await fetchProjects(); // Refresh the project list
    } catch (error) {
      console.error('Error restoring project:', error);
      enqueueSnackbar(`Failed to restore project "${project.name}". Please try again.`, { variant: 'error' });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return moment.utc(dateString).local().format('MMM D, YYYY');
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Manage Projects
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog(null, 'one-off')}
            sx={{ backgroundColor: '#3498db', '&:hover': { backgroundColor: '#2980b9' } }}
          >
            Add New One-off Project
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog(null, 'retainer')}
            sx={{ backgroundColor: '#2ecc71', '&:hover': { backgroundColor: '#27ae60' } }}
          >
            Add New Retainer Project
          </Button>
        </Box>
        <TextField
          variant="outlined"
          placeholder="Search projects"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: '300px' }}
        />
      </Box>

      <StyledTableContainer component={Paper}>
        <TableContainer>
          <Table stickyHeader>
            <StyledTableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                {isAdmin && <TableCell align="right">Budget/Retainer</TableCell>}
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {filteredProjects.map((project, index) => {
                const clientName = clients.find(c => c.id === project.clientId)?.name;
                const retainer = retainers.find(r => r.id === project.retainerId);
                const retainerInfo = retainer ? `${retainer.retainerName} (${clientName})` : '';

                return (
                  <React.Fragment key={project.id}>
                    {index > 0 && filteredProjects[index - 1].status === 'active' && project.status === 'inactive' && (
                      <TableRow>
                        <TableCell colSpan={isAdmin ? 8 : 7} sx={{ backgroundColor: alpha('#bdc3c7', 0.2), py: 1 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#7f8c8d' }}>
                            Completed Projects
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow hover>
                      <TableCell>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                          {project.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{project.projectType === 'retainer' ? retainerInfo : clientName}</TableCell>
                      <TableCell>
                        <Chip 
                          label={project.projectType}
                          size="small"
                          color={project.projectType === 'retainer' ? 'primary' : 'secondary'}
                        />
                      </TableCell>
                      <TableCell>
                        {isPastDue(project) ? (
                          <Chip 
                            label="Past Due"
                            size="small"
                            sx={{ 
                              backgroundColor: alpha('#e74c3c', 0.2), 
                              color: '#e74c3c'
                            }}
                          />
                        ) : project.status === 'active' ? (
                          <Chip 
                            label="Active"
                            size="small"
                            sx={{ 
                              backgroundColor: alpha('#3498db', 0.2), 
                              color: '#3498db'
                            }}
                          />
                        ) : (
                          <Tooltip title={`Completed on ${formatDate(project.updatedAt)}`} arrow>
                            <Chip 
                              label="Completed"
                              size="small"
                              sx={{ 
                                backgroundColor: alpha('#2ecc71', 0.2), 
                                color: '#2ecc71'
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      {isAdmin && (
                        <TableCell align="right">
                          {project.projectType === 'retainer' 
                            ? formatCurrency(retainer?.amountPaid) 
                            : formatCurrency(project.budget)}
                        </TableCell>
                      )}
                      <TableCell>{formatDisplayDate(project.startDate)}</TableCell>
                      <TableCell>{formatDisplayDate(project.endDate)}</TableCell>
                      <TableCell align="right">
                        <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(project)}>
                          <EditIcon />
                        </IconButton>
                        {isAdmin && (
                          <IconButton edge="end" aria-label="delete" onClick={() => handleOpenDeleteDialog(project)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {isAdmin && (
                          <Button
                            size="small"
                            onClick={() => handleStatusChange(project, project.status === 'active' ? 'inactive' : 'active')}
                            variant="outlined"
                            sx={{ ml: 2 }}
                          >
                            {project.status === 'active' ? 'Complete' : 'Reactivate'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editingProject ? 'Edit Project' : `Add New ${projectType === 'one-off' ? 'One-off' : 'Retainer'} Project`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Project Name"
            type="text"
            fullWidth
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          
          {projectType === 'one-off' && (
            <FormControl fullWidth margin="dense">
              <InputLabel id="client-select-label">Client</InputLabel>
              <Select
                labelId="client-select-label"
                id="client"
                value={clientId}
                label="Client"
                onChange={(e) => {
                  setClientId(e.target.value);
                  setRetainerId(''); // Reset retainer when client changes
                }}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {isAdmin && projectType === 'one-off' && (
            <TextField
              margin="dense"
              id="budget"
              label="Budget"
              type="text"
              fullWidth
              value={budget}
              onChange={(e) => {
                const formattedValue = formatBudgetInput(e.target.value);
                setBudget(formattedValue);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          )}

          {projectType === 'retainer' && (
            <FormControl fullWidth margin="dense">
              <InputLabel id="retainer-select-label">Retainer</InputLabel>
              <Select
                labelId="retainer-select-label"
                id="retainer"
                value={retainerId}
                label="Retainer"
                onChange={(e) => {
                  setRetainerId(e.target.value);
                  const selectedRetainer = retainers.find(r => r.id === e.target.value);
                  setClientId(selectedRetainer ? selectedRetainer.clientId : '');
                }}
              >
                {retainers
                  .filter(retainer => retainer.status === 'active')
                  .map((retainer) => {
                    const clientName = clients.find(c => c.id === retainer.clientId)?.name;
                    return (
                      <MenuItem key={retainer.id} value={retainer.id}>
                        <Box display="flex" alignItems="center">
                          <span>{retainer.retainerName}</span>
                          <Chip
                            label={clientName}
                            size="small"
                            sx={{ ml: 1 }}
                          />
                        </Box>
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
          )}

          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="endDate"
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : (editingProject ? 'Update' : 'Add')}
          </Button>
        </DialogActions>
      </Dialog>

      {isAdmin && (
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this project?"}</DialogTitle>
          <DialogContent>
            <Typography>
              This action cannot be undone. All data associated with this project will be permanently deleted.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleDeleteProject} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default ManageProjects;