import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClients } from '../contexts/ClientContext';
import { useUser } from '../contexts/UserContext';
import { useProjects } from '../contexts/ProjectContext';
import { useHours } from '../contexts/HoursContext';
import { useRetainers } from '../contexts/RetainerContext';  // Import RetainerContext

function LogHours() {
  const { clients } = useClients();
  const { user, users, fetchUsers } = useUser();
  const { addHoursLog } = useHours();
  const { projects } = useProjects();
  const { retainers, loading: retainersLoading, error: retainersError } = useRetainers();  // Get retainers from context
  const [projectId, setProjectId] = useState('');
  const [hours, setHours] = useState('');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [description, setDescription] = useState('');
  const [creativeId, setCreativeId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sessionLogs, setSessionLogs] = useState([]);

  const activeProjects = useMemo(() => projects.filter(project => project.status === 'active' && !project.isDeleted), [projects]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (user) {
      setCreativeId(user.id);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const currentTime = moment().format('h:mm A');
    const hoursData = {
      projectId,
      creativeId: user.permission === 'admin' || user.permission === 'manager' ? creativeId : user.id,
      hours,
      date: moment(date).utc().format(),  // Convert to UTC ISO string
      description,
      loggedAt: currentTime,
    };

    try {
      await addHoursLog(hoursData);
      setSessionLogs(prevLogs => [...prevLogs, hoursData]);
      resetForm();
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error logging hours:', error);
    }
  };

  const resetForm = () => {
    setProjectId('');
    setHours('');
    setDate(moment().format('YYYY-MM-DD'));
    setDescription('');
    if (user.permission === 'admin' || user.permission === 'manager') {
      setCreativeId('');
    }
  };

  if (retainersLoading) return <Typography>Loading retainers data...</Typography>;
  if (retainersError) return <Typography>Error loading retainers: {retainersError}</Typography>;

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Log Hours
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="project-select-label">Project</InputLabel>
            <Select
              labelId="project-select-label"
              id="project"
              value={projectId}
              label="Project"
              onChange={(e) => setProjectId(e.target.value)}
              required
            >
              {activeProjects.map((project) => {
                const clientName = clients.find(c => c.id === project.clientId)?.name;
                return (
                  <MenuItem key={project.id} value={project.id}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <span>{project.name}</span>
                      <Chip
                        label={clientName}
                        size="small"
                        sx={{
                          bgcolor: '#e6f7ff',  // Lighter blue background
                          color: '#0066cc',    // Darker blue text
                          '& .MuiChip-label': { fontSize: '0.75rem' }
                        }}
                      />
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {(user.permission === 'admin' || user.permission === 'manager') && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="creative-select-label">User</InputLabel>
              <Select
                labelId="creative-select-label"
                id="creative"
                value={creativeId}
                label="User"
                onChange={(e) => setCreativeId(e.target.value)}
                required
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <span>{user.name}</span>
                      <Chip label={user.role} size="small" />
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="hours"
            label="Hours Worked"
            name="hours"
            type="number"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="Date"
            name="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { max: moment().format('YYYY-MM-DD') }
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="description"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log Hours
          </Button>
        </Box>
      </Box>
      {sessionLogs.length > 0 && (
        <Box sx={{ mt: 4, mb: 8 }}> 
          <Typography variant="h5" component="h2" gutterBottom>
            Current Session Logs
          </Typography>
          <List sx={{ 
            bgcolor: 'background.paper', 
            borderRadius: 2, 
            boxShadow: 2, 
            overflow: 'hidden',
            mb: 4 // Added mb: 4 for additional bottom margin on the List
          }}>
            {sessionLogs.map((log, index) => {
              const project = activeProjects.find(p => p.id === log.projectId);
              const client = clients.find(c => c.id === project?.clientId);
              const user = users.find(u => u.id === log.creativeId);

              const formattedDateTime = moment(log.date).format('ddd, MMM D, YYYY') + ' at ' + log.loggedAt;

              return (
                <ListItem 
                  key={index} 
                  sx={{ 
                    borderBottom: index < sessionLogs.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
                    py: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
                      <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                        {user?.name || 'Unknown'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        logged for
                      </Typography>
                      <Chip 
                        label={client?.name} 
                        size="small" 
                        sx={{
                          bgcolor: '#e6f7ff',  // Lighter blue background
                          color: '#0066cc',    // Darker blue text
                          '& .MuiChip-label': { fontSize: '0.75rem' }
                        }}
                      />
                      <Chip 
                        label={project?.name} 
                        size="small" 
                        sx={{
                          bgcolor: '#f0f0f0',  // Light grey background
                          color: '#333333',    // Dark grey text
                          '& .MuiChip-label': { fontSize: '0.75rem' }
                        }}
                      />
                      <Chip label={`${log.hours} hours`} size="small" color="secondary" />
                    </Box>
                    {log.description && (
                      <Box sx={{ mt: 1, display: 'flex', alignItems: 'baseline' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold', mr: 1 }}>
                          Description:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {log.description}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ minWidth: '150px', textAlign: 'right' }}>
                    {formattedDateTime}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Hours logged successfully"
      />
    </Container>
  );
}

export default LogHours;