import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  InputAdornment,
  Chip,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useClients } from '../contexts/ClientContext';
import { useRetainers } from '../contexts/RetainerContext';
import moment from 'moment-timezone';
import { useProjects } from '../contexts/ProjectContext';
import API_BASE_URL from '../config';
import { useSnackbar } from 'notistack';
import { styled, alpha } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  '&:before': { display: 'none' },
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  padding: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: '#E5E5E6',
  color: '#1E1E1E',
  fontWeight: 'medium',
  fontSize: '0.75rem',
  height: '24px',
  borderRadius: '12px',
  padding: '0 8px',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

function ManageRetainers() {
  const { clients } = useClients();
  const { retainers, loading, error, fetchRetainers, addRetainer, updateRetainer, deleteRetainer, associatedProjectsAndHours } = useRetainers();
  const { projects, updateProject, updateMultipleProjects, fetchProjects } = useProjects();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [editingRetainer, setEditingRetainer] = useState(null);
  const [clientId, setClientId] = useState('');
  const [hoursPurchased, setHoursPurchased] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingRetainer, setDeletingRetainer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [retainerName, setRetainerName] = useState('');
  const [expandedRetainer, setExpandedRetainer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (retainers && projects) {
      setIsLoading(false);
    }
  }, [retainers, projects]);

  const filteredRetainers = useMemo(() => {
    if (!retainers || !clients) return [];

    const activeRetainers = retainers.filter(r => r.status === 'active' && !r.isDeleted);
    const inactiveRetainers = retainers.filter(r => r.status === 'inactive' && !r.isDeleted);
    const allRetainers = [...activeRetainers, ...inactiveRetainers];
    
    return allRetainers.filter(retainer => {
      const clientName = clients.find(c => c.id === retainer.clientId)?.name || '';
      const searchString = `${retainer.retainerName} ${clientName}`.toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });
  }, [retainers, clients, searchTerm]);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return moment.utc(dateString).local().format('MMM D, YYYY');
  };

  const isPastDue = (retainer) => {
    return retainer.status === 'active' && retainer.endDate && moment().isAfter(moment(retainer.endDate));
  };

  const handleOpenDialog = (retainer = null) => {
    if (retainer) {
      setEditingRetainer(retainer);
      setRetainerName(retainer.retainerName);
      setClientId(retainer.clientId);
      setHoursPurchased(retainer.hoursPurchased);
      setAmountPaid(retainer.amountPaid);
      setStartDate(retainer.startDate ? moment.utc(retainer.startDate).local().format('YYYY-MM-DD') : '');
      setEndDate(retainer.endDate ? moment.utc(retainer.endDate).local().format('YYYY-MM-DD') : '');
    } else {
      setEditingRetainer(null);
      setRetainerName('');
      setClientId('');
      setHoursPurchased('');
      setAmountPaid('');
      setStartDate('');
      setEndDate('');
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingRetainer(null);
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidNumber(hoursPurchased) || !isValidNumber(amountPaid)) {
      enqueueSnackbar('Please enter valid numbers for Hours Purchased and Amount Paid', { variant: 'error' });
      return;
    }

    const retainerData = {
      retainerName,
      clientId,
      hoursPurchased: parseInt(hoursPurchased, 10),
      amountPaid: parseFloat(amountPaid),
      startDate: startDate ? moment(startDate).utc().format() : null,
      endDate: endDate ? moment(endDate).utc().format() : null
    };

    try {
      if (editingRetainer) {
        await updateRetainer(editingRetainer.id, retainerData);
        enqueueSnackbar('Retainer updated successfully', { variant: 'success' });
      } else {
        await addRetainer(retainerData);
        enqueueSnackbar('Retainer added successfully', { variant: 'success' });
      }
      handleCloseDialog();
      await fetchRetainers();
    } catch (error) {
      console.error('Error updating/adding retainer:', error);
      enqueueSnackbar(`Failed to ${editingRetainer ? 'update' : 'add'} retainer: ${error.message}`, { variant: 'error' });
    }
  };

  const handleOpenDeleteDialog = (retainer) => {
    setDeletingRetainer(retainer);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeletingRetainer(null);
  };

  const handleDeleteRetainer = async () => {
    try {
      await deleteRetainer(deletingRetainer.id);
      enqueueSnackbar('Retainer archived successfully', { variant: 'success' });
      handleCloseDeleteDialog();
      await fetchRetainers();
    } catch (error) {
      console.error('Error archiving retainer:', error);
      enqueueSnackbar('Failed to archive retainer. Please try again.', { variant: 'error' });
    }
  };

  const handleCompleteRetainer = async (retainer) => {
    try {
      const completedDate = moment().utc().format();
      const updatedRetainer = { ...retainer, status: 'inactive', completedDate };
      await updateRetainer(retainer.id, updatedRetainer);
      
      const retainerProjects = projects.filter(p => p.retainerId === retainer.id && p.status === 'active');
      if (retainerProjects.length > 0) {
        const projectUpdates = retainerProjects.map(project => ({
          id: project.id,
          status: 'inactive'
        }));
        await updateMultipleProjects(projectUpdates);
      }

      await fetchRetainers();
      await fetchProjects();

      enqueueSnackbar(`Retainer "${retainer.retainerName}" and associated projects marked as completed`, { variant: 'success' });
    } catch (error) {
      console.error('Error completing retainer:', error);
      enqueueSnackbar(`Failed to mark retainer "${retainer.retainerName}" as completed: ${error.message}`, { variant: 'error' });
    }
  };

  const handleReactivateRetainer = async (retainer) => {
    try {
      const updatedRetainer = { ...retainer, status: 'active' };
      await updateRetainer(retainer.id, updatedRetainer);
      
      await fetchRetainers();

      enqueueSnackbar(`Retainer "${retainer.retainerName}" reactivated successfully`, { variant: 'success' });
    } catch (error) {
      console.error('Error reactivating retainer:', error);
      enqueueSnackbar(`Failed to reactivate retainer "${retainer.retainerName}": ${error.message}`, { variant: 'error' });
    }
  };

  const sortRetainers = (retainers) => {
    return [...retainers].sort((a, b) => {
      const dateA = moment.utc(a.updatedAt || a.startDate);
      const dateB = moment.utc(b.updatedAt || b.startDate);
      return dateB.valueOf() - dateA.valueOf();
    });
  };

  const renderRetainerTable = () => {
    if (isLoading || !retainers || !projects) {
      return <Typography>Loading retainers...</Typography>;
    }

    if (filteredRetainers.length === 0) {
      return <Typography>No retainers found.</Typography>;
    }

    const activeRetainers = filteredRetainers.filter(r => r.status === 'active');
    const completedRetainers = filteredRetainers.filter(r => r.status === 'inactive');

    return (
      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Retainer Name</TableCell>
              <TableCell>Client</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Hours Used</TableCell>
              <TableCell align="center">Total Projects</TableCell>
              <TableCell align="center">Amount Paid</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {[...activeRetainers, ...completedRetainers].map((retainer, index) => {
              const clientName = clients.find(c => c.id === retainer.clientId)?.name;
              const projectCounts = associatedProjectsAndHours[retainer.id] || { active: 0, inactive: 0, hoursUsed: 0 };

              return (
                <React.Fragment key={retainer.id}>
                  {index === activeRetainers.length && (
                    <TableRow>
                      <TableCell colSpan={9} sx={{ backgroundColor: alpha('#bdc3c7', 0.2), py: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#7f8c8d' }}>
                          Completed Retainers
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow hover>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                        {retainer.retainerName}
                      </Typography>
                    </TableCell>
                    <TableCell>{clientName}</TableCell>
                    <TableCell align="center">
                      {isPastDue(retainer) ? (
                        <StatusChip 
                          label="Past Due" 
                          sx={{
                            backgroundColor: alpha('#e74c3c', 0.2),
                            color: '#e74c3c'
                          }}
                        />
                      ) : retainer.status === 'active' ? (
                        <StatusChip 
                          label="Active" 
                          sx={{
                            backgroundColor: alpha('#3498db', 0.2),
                            color: '#3498db'
                          }}
                        />
                      ) : (
                        <Tooltip title={`Completed on ${formatDate(retainer.updatedAt)}`} arrow>
                          <StatusChip 
                            label="Completed" 
                            sx={{
                              backgroundColor: alpha('#2ecc71', 0.2),
                              color: '#2ecc71'
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{projectCounts.hoursUsed}/{formatNumber(retainer.hoursPurchased)}</TableCell>
                    <TableCell align="center">{projectCounts.active + projectCounts.inactive}</TableCell>
                    <TableCell align="center">${formatNumber(retainer.amountPaid)}</TableCell>
                    <TableCell align="center">{formatDate(retainer.startDate)}</TableCell>
                    <TableCell align="center">{formatDate(retainer.endDate)}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleOpenDialog(retainer)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleOpenDeleteDialog(retainer)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Button
                        size="small"
                        onClick={() => retainer.status === 'active' ? handleCompleteRetainer(retainer) : handleReactivateRetainer(retainer)}
                        variant="outlined"
                        sx={{ ml: 2 }}
                      >
                        {retainer.status === 'active' ? 'Complete' : 'Reactivate'}
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 2, mb: 2, p: 2, borderRadius: '8px' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
          Manage Retainers
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{ mb: 0 }}
          >
            Add New Retainer
          </Button>
          <TextField
            variant="outlined"
            placeholder="Search retainers"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: '300px' }}
          />
        </Box>
      </Box>

      {renderRetainerTable()}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editingRetainer ? 'Edit Retainer' : 'Add New Retainer'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="retainerName"
            label="Retainer Name"
            type="text"
            fullWidth
            value={retainerName}
            onChange={(e) => setRetainerName(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client"
              value={clientId}
              label="Client"
              onChange={(e) => setClientId(e.target.value)}
            >
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="hoursPurchased"
            label="Hours Purchased"
            type="text"
            fullWidth
            value={hoursPurchased}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d]/g, '');
              setHoursPurchased(value);
            }}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
          <TextField
            margin="dense"
            id="amountPaid"
            label="Amount Paid"
            type="text"
            fullWidth
            value={amountPaid}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d]/g, '');
              setAmountPaid(value);
            }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="endDate"
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit}>{editingRetainer ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete the retainer "${deletingRetainer?.retainerName}"?`}</DialogTitle>
        <DialogContent>
          <Typography>
            This action cannot be undone. All data associated with this retainer will be permanently deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteRetainer} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageRetainers;