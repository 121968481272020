import React, { useState, useEffect } from 'react';
import { Box, Paper, List, ListItem, ListItemText, Typography, Divider, ListItemIcon, ListItemButton } from '@mui/material';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import BusinessIcon from '@mui/icons-material/Business';
import RepeatIcon from '@mui/icons-material/Repeat';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import { useUser } from '../contexts/UserContext';

import ManageClients from './ManageClients';
import ManageRetainers from './ManageRetainers';
import ManageProjects from './ManageProjects';
import ManageCreatives from './ManageCreatives';

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>{error.message}</pre>
    </div>
  )
}

const Manage = () => {
  const [selectedItem, setSelectedItem] = useState('Projects');
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    console.log('Manage component mounted');
    return () => console.log('Manage component unmounted');
  }, []);

  useEffect(() => {
    console.log('Route changed:', location.pathname);
  }, [location]);

  const handleItemClick = (text, path) => {
    setSelectedItem(text);
    navigate(path);
  };

  const iconColor = '#3498db';

  const menuItems = user && user.permission === 'admin' ? [
    { text: 'Projects', icon: <WorkIcon sx={{ color: iconColor }} />, path: '/manage/projects' },
    { text: 'Clients', icon: <BusinessIcon sx={{ color: iconColor }} />, path: '/manage/clients' },
    { text: 'Retainers', icon: <RepeatIcon sx={{ color: iconColor }} />, path: '/manage/retainers' },
    { text: 'Users', icon: <PersonIcon sx={{ color: iconColor }} />, path: '/manage/users' },
  ] : [
    { text: 'Projects', icon: <WorkIcon sx={{ color: iconColor }} />, path: '/manage/projects' },
  ];

  return (
    <Box sx={{ 
      display: 'flex', 
      height: 'calc(100vh - 64px)', 
      position: 'fixed',
      top: 64,
      left: 0,
      right: 0,
      bottom: 0,
    }}>
      {/* Sidebar */}
      <Paper 
        elevation={3} 
        sx={{ 
          width: 240, 
          height: '100%',
          overflowY: 'auto',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          margin: 0,
        }}
      >
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>Manage</Typography>
        <Divider />
        <List sx={{ flexGrow: 1, padding: 0 }}>
          {menuItems.map((item) => (
            <ListItem 
              key={item.text}
              disablePadding
            >
              <ListItemButton
                onClick={() => handleItemClick(item.text, item.path)}
                selected={selectedItem === item.text}
                sx={{
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 4,
                      bottom: 4,
                      width: 3,
                      backgroundColor: 'primary.main',
                      borderRadius: '0 2px 2px 0',
                    },
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Main content */}
      <Box sx={{ 
        flexGrow: 1, 
        backgroundColor: '#f5f5f5',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            {user && user.permission === 'admin' ? (
              <>
                <Route index element={<Navigate to="projects" replace />} />
                <Route path="projects" element={<ManageProjects />} />
                <Route path="clients" element={<ManageClients />} />
                <Route path="retainers" element={<ManageRetainers />} />
                <Route path="users" element={<ManageCreatives />} />
              </>
            ) : (
              <>
                <Route index element={<Navigate to="projects" replace />} />
                <Route path="projects" element={<ManageProjects />} />
                <Route path="*" element={<Navigate to="projects" replace />} />
              </>
            )}
          </Routes>
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default Manage;