import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { UserProvider } from './contexts/UserContext';
import { ClientProvider } from './contexts/ClientContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { HoursProvider } from './contexts/HoursContext';
import { RetainerProvider } from './contexts/RetainerContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LogHours from './components/LogHours';
import Manage from './components/Manage';
import Reports from './components/Reports';
import NavBar from './components/NavBar';

const theme = createTheme();

function App() {
  return (
    <UserProvider>
      <ClientProvider>
        <ProjectProvider>
          <HoursProvider>
            <RetainerProvider>
              <Router>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <NavBar />
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={
                      <ProtectedRoute allowedRoles={['admin', 'manager']}>
                        <Dashboard />
                      </ProtectedRoute>
                    } />
                    {/* Remove the ManagerDashboard route */}
                    <Route path="/log-hours" element={
                      <ProtectedRoute allowedRoles={['member', 'manager', 'admin']}>
                        <LogHours />
                      </ProtectedRoute>
                    } />
                    <Route path="/manage/*" element={
                      <ProtectedRoute allowedRoles={['admin', 'manager']}>
                        <Manage />
                      </ProtectedRoute>
                    } />
                    <Route path="/reports" element={
                      <ProtectedRoute allowedRoles={['admin', 'manager']}>
                        <Reports />
                      </ProtectedRoute>
                    } />
                  </Routes>
                </ThemeProvider>
              </Router>
            </RetainerProvider>
          </HoursProvider>
        </ProjectProvider>
      </ClientProvider>
    </UserProvider>
  );
}

export default App;