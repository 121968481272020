import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useRetainers } from '../contexts/RetainerContext';
import { useProjects } from '../contexts/ProjectContext';
import { useUser } from '../contexts/UserContext';
import { useHours } from '../contexts/HoursContext';
import { useClients } from '../contexts/ClientContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Card, CardContent, Avatar, LinearProgress, Tooltip, TextField, InputAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import moment from 'moment-timezone';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // Increased shadow spread and opacity
  '&:before': { display: 'none' },
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)', // Even larger shadow on hover
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  padding: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1), // Add some space between the chip and the arrow
  },
  '& .MuiChip-root': {
    marginLeft: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Center vertically
  alignItems: 'center', // Center horizontally
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  '& th': {
    fontWeight: 'bold',
    color: '#2c3e50',
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: '#E5E5E6',  // Light blue background
  color: '#1E1E1E',            // Blue text
  fontWeight: 'medium',
  fontSize: '0.75rem',
  height: '24px',
  borderRadius: '12px',        // More rounded corners
  padding: '0 8px',
}));

const colorPalette = ['#3498db', '#2ecc71', '#f39c12', '#9b59b6', '#1abc9c', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#f1c40f', '#e67e22', '#95a5a6', '#d35400', '#bdc3c7'];

const renderProgressBar = (projectSpends, total, valueKey, tooltipLabel) => {
  let accumulatedPercentage = 0;
  const totalUsage = projectSpends.reduce((sum, project) => sum + project[valueKey], 0);
  const scaleFactor = totalUsage > total ? total / totalUsage : 1;
  
  return (
    <Box sx={{ position: 'relative', height: 10, borderRadius: 5, backgroundColor: '#e0e0e0', overflow: 'hidden' }}>
      {projectSpends.map((project, index) => {
        const rawPercentage = (project[valueKey] / total) * 100;
        const scaledPercentage = rawPercentage * scaleFactor;
        const startPercentage = accumulatedPercentage;
        accumulatedPercentage = Math.min(accumulatedPercentage + scaledPercentage, 100);
        
        return (
          <Tooltip
            key={project.project.id}
            title={`${project.project.name}: ${project[valueKey].toFixed(2)} ${tooltipLabel} (${rawPercentage.toFixed(1)}%)`}
            arrow
            placement="top"
          >
            <Box
              sx={{
                position: 'absolute',
                left: `${startPercentage}%`,
                width: `${accumulatedPercentage - startPercentage}%`,
                height: '100%',
                backgroundColor: colorPalette[index % colorPalette.length],
                transition: 'width 0.3s ease-in-out',
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

const RetainerReports = () => {
  const [expandedRetainer, setExpandedRetainer] = useState(null);

  const { retainers, loading: retainersLoading } = useRetainers();
  const { projects, loading: projectsLoading } = useProjects();
  const { users, loading: usersLoading, fetchUsers } = useUser();
  const { hoursLogs, loading: hoursLoading, fetchHoursLogs } = useHours();
  const { user } = useUser();
  const isAdmin = user && user.permission === 'admin';
  const { clients, loading: clientsLoading } = useClients();
  const [searchTerm, setSearchTerm] = useState('');

  const formatDate = useCallback((dateString) => {
    if (!dateString) return 'N/A';
    return moment.utc(dateString).local().format('MMM D, YYYY');
  }, []);

  const isPastDue = (retainer) => {
    return retainer.status === 'active' && retainer.endDate && moment.utc().isAfter(moment.utc(retainer.endDate));
  };

  useEffect(() => {
    fetchHoursLogs();
    if (users.length === 0 && !usersLoading) {
      fetchUsers();
    }
  }, [fetchHoursLogs, fetchUsers, users, usersLoading]);

  const calculateProjectSpend = useCallback((projectId) => {
    const projectHoursLogs = hoursLogs.filter(log => log.projectId === projectId);
    
    const spend = projectHoursLogs.reduce((total, log) => {
      const user = users.find(u => u.id === log.creativeId);
      const rate = user ? user.rate : 0;
      const logSpend = log.hours * rate;
      return total + logSpend;
    }, 0);

    return spend;
  }, [hoursLogs, users]);

  const retainerData = useMemo(() => {
    if (retainersLoading || projectsLoading || usersLoading || hoursLoading || clientsLoading) {
      return null;
    }

    const activeRetainers = retainers.filter(retainer => !retainer.isDeleted);
    const activeProjects = projects.filter(project => !project.isDeleted);

    return activeRetainers.map(retainer => {
      const client = clients.find(client => client.id === retainer.clientId);
      const clientName = client ? client.name : 'Unknown Client';

      const retainerProjects = activeProjects.filter(project => project.retainerId === retainer.id);
      
      // Calculate hoursUsed only for non-deleted projects
      const hoursUsed = retainerProjects.reduce((total, project) => {
        const projectHours = hoursLogs
          .filter(log => log.projectId === project.id)
          .reduce((sum, log) => sum + log.hours, 0);
        return total + projectHours;
      }, 0);
      
      const projectSpends = retainerProjects.map(project => {
        const projectHours = hoursLogs
          .filter(log => log.projectId === project.id)
          .reduce((total, log) => total + log.hours, 0);
        
        const projectSpend = calculateProjectSpend(project.id);
        
        return { project, hours: projectHours, spend: projectSpend };
      });
      
      const totalSpent = projectSpends.reduce((total, { spend }) => total + spend, 0);
      const budgetRemaining = retainer.amountPaid - totalSpent;
      const hoursRemaining = retainer.hoursPurchased - hoursUsed;
      
      return { 
        retainer, 
        clientName,
        retainerProjects, 
        hoursUsed, 
        hoursRemaining,
        totalSpent, 
        budgetRemaining,
        projectSpends 
      };
    });
  }, [retainers, projects, hoursLogs, retainersLoading, projectsLoading, hoursLoading, usersLoading, calculateProjectSpend, clients, clientsLoading]);

  const filteredRetainerData = useMemo(() => {
    if (!retainerData) return [];
    return retainerData.filter(({ retainer, clientName, retainerProjects }) => {
      const searchString = `
        ${retainer.retainerName}
        ${clientName}
        ${retainerProjects.map(p => p.name).join(' ')}
      `.toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });
  }, [retainerData, searchTerm]);

  const isLoading = retainersLoading || projectsLoading || usersLoading || hoursLoading || clientsLoading;
  const hasData = retainers.length > 0 && projects.length > 0 && hoursLogs.length > 0;

  if (isLoading) {
    return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
  }

  if (!hasData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="text.secondary">
          No data available. Please check data loading.
        </Typography>
      </Box>
    );
  }

  if (!retainerData) {
    return null;
  }

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Retainer Reports
      </Typography>

      {/* Add search bar */}
      <Box sx={{ mt: 4, mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by retainer name, client name, or project"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {filteredRetainerData.map(({ retainer, clientName, retainerProjects, hoursUsed, hoursRemaining, totalSpent, budgetRemaining, projectSpends }) => (
        <StyledAccordion
          key={retainer.id}
          expanded={expandedRetainer === retainer.id}
          onChange={() => setExpandedRetainer(expandedRetainer === retainer.id ? null : retainer.id)}
        >
          <StyledAccordionSummary 
            expandIcon={<ExpandMoreIcon />}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Avatar sx={{ bgcolor: '#3498db', color: '#fff', mr: 2 }}>
                  <AttachMoneyIcon />
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ color: '#2c3e50' }}>
                    {retainer.retainerName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(retainer.startDate)} - {formatDate(retainer.endDate)}
                  </Typography>
                </Box>
                <Chip
                  label={clientName}
                  size="small"
                  sx={{ ml: 2, height: 24 }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ color: '#34495e', mr: 2 }}>
                  {retainerProjects.length} Projects | {hoursUsed}/{retainer.hoursPurchased} Hours Used
                </Typography>
                {isPastDue(retainer) ? (
                  <StatusChip 
                    label="Past Due" 
                    sx={{
                      backgroundColor: alpha('#e74c3c', 0.2),
                      color: '#e74c3c'
                    }}
                  />
                ) : retainer.status === 'active' ? (
                  <StatusChip 
                    label="Active" 
                    sx={{
                      backgroundColor: alpha('#3498db', 0.2),
                      color: '#3498db'
                    }}
                  />
                ) : (
                  <Tooltip title={`Completed on ${formatDate(retainer.updatedAt)}`} arrow>
                    <StatusChip 
                      label="Completed" 
                      sx={{
                        backgroundColor: alpha('#2ecc71', 0.2),
                        color: '#2ecc71'
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ p: 3, backgroundColor: '#fff' }}>
            
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {[
                { icon: <AccessTimeIcon />, label: 'Hours Used', value: `${hoursUsed}/${retainer.hoursPurchased}`, color: '#9b59b6' },
                { icon: <FolderIcon />, label: 'Total Projects', value: retainerProjects.length, color: '#3498db' },
                { icon: <AttachMoneyIcon />, label: 'Client', value: clientName, color: '#f39c12' },
                ...(isAdmin ? [
                  { icon: <AttachMoneyIcon />, label: 'Budget', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(retainer.amountPaid), color: '#2ecc71' },
                  { icon: <AttachMoneyIcon />, label: 'Spent', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalSpent), color: '#e74c3c' },
                ] : []),
              ].map((item, index) => (
                <Grid item xs={6} sm={4} md={isAdmin ? 2.4 : 4} key={index}>
                  <StyledCard>
                    <Avatar sx={{ bgcolor: item.color, mb: 1 }}>{item.icon}</Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>{item.value}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>{item.label}</Typography>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            {/* Progress Bars */}
            <Box sx={{ mt: 8, mb: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50', mt: 2 }}>Retainer Usage</Typography>
              <Typography variant="body2" color="textSecondary">Hours Used</Typography>
              {renderProgressBar(projectSpends, Math.max(retainer.hoursPurchased, hoursUsed), 'hours', 'hours')}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 3 }}>
                <Typography variant="body2" color="textSecondary">
                  {hoursUsed.toFixed(2)} hours used
                </Typography>
                <Typography variant="body2" color={hoursRemaining < 0 ? 'warning.main' : 'textSecondary'}>
                  {hoursRemaining < 0 ? `${Math.abs(hoursRemaining).toFixed(2)} hours over` : `${hoursRemaining.toFixed(2)} hours remaining`}
                </Typography>
              </Box>

              {isAdmin && (
                <>
                  <Typography variant="body2" color="textSecondary">Budget Used</Typography>
                  {renderProgressBar(projectSpends, Math.max(retainer.amountPaid, totalSpent), 'spend', 'USD')}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Typography variant="body2" color="textSecondary">
                      ${totalSpent.toFixed(2)} spent
                    </Typography>
                    <Typography variant="body2" color={budgetRemaining < 0 ? 'warning.main' : 'textSecondary'}>
                      {budgetRemaining < 0 ? `$${Math.abs(budgetRemaining).toFixed(2)} over budget` : `$${budgetRemaining.toFixed(2)} remaining`}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            {/* Projects Table */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>Retainer Projects</Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Hours Spent</TableCell>
                    {isAdmin && <TableCell align="right">Amount Spent</TableCell>}
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {projectSpends.map(({ project, hours, spend }) => (
                    <TableRow key={project.id} hover>
                      <TableCell>{project.name}</TableCell>
                      <TableCell>
                        <Chip 
                          label={project.status} 
                          color={project.status === 'Active' ? 'success' : 'default'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">{hours.toFixed(2)}</TableCell>
                      {isAdmin && <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(spend)}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default RetainerReports;