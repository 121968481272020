import React, { useState, useEffect, useMemo } from 'react';
import { 
  Typography, 
  Box, 
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';
import { useUser } from '../contexts/UserContext';
import { styled, alpha } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

function ManageCreatives() {
  const { users, fetchUsers, addUser, updateUser, deleteUser } = useUser();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({ name: '', username: '', role: '', rate: '', permission: 'member', password: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleOpen = (user = { name: '', username: '', role: '', rate: '', permission: 'member', password: '' }) => {
    setCurrentUser(user);
    setIsEditing(!!user.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser({ name: '', username: '', role: '', rate: '', permission: 'member', password: '' });
  };

  const handleSave = async () => {
    if (currentUser.id) {
      const userData = { ...currentUser };
      if (!currentUser.password) {
        delete userData.password;
      }
      const updatedUser = await updateUser(currentUser.id, userData);
      // Update the local state instead of refetching all users
      const updatedUsers = users.map(user => 
        user.id === updatedUser.id ? updatedUser : user
      );
      fetchUsers(updatedUsers); // Assuming fetchUsers can accept an array to update state
    } else {
      if (!currentUser.password) {
        alert('Password is required when adding a new user.');
        return;
      }
      const newUser = await addUser(currentUser);
      fetchUsers([...users, newUser]); // Add the new user to the existing array
    }
    handleClose();
  };

  const confirmDeleteUser = (user) => {
    setDeletingUser(user);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (deletingUser) {
      await deleteUser(deletingUser.id);
      fetchUsers();
    }
    setOpenDeleteDialog(false);
    setDeletingUser(null);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setDeletingUser(null);
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Manage Team
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />} 
          onClick={() => handleOpen()}
          sx={{ mb: 2 }}
        >
          Add User
        </Button>

        <TextField
          variant="outlined"
          placeholder="Search users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: '300px' }}
        />
      </Box>

      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Hourly Rate</TableCell>
              <TableCell>Permission</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id} hover>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>${user.rate}/hour</TableCell>
                <TableCell>
                  <Chip 
                    label={user.permission}
                    color={user.permission === 'admin' ? 'secondary' : user.permission === 'manager' ? 'primary' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(user)} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => confirmDeleteUser(user)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Add/Edit User Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentUser.id ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentUser.name}
            onChange={(e) => setCurrentUser({...currentUser, name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={currentUser.username}
            onChange={(e) => setCurrentUser({...currentUser, username: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Role (e.g., Cinematographer)"
            type="text"
            fullWidth
            value={currentUser.role}
            onChange={(e) => setCurrentUser({...currentUser, role: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Hourly Rate"
            type="number"
            fullWidth
            value={currentUser.rate}
            onChange={(e) => setCurrentUser({...currentUser, rate: e.target.value})}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="permission-label">Permission</InputLabel>
            <Select
              labelId="permission-label"
              value={currentUser.permission}
              label="Permission"
              onChange={(e) => setCurrentUser({ ...currentUser, permission: e.target.value })}
            >
              <MenuItem value="member">Member</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={currentUser.password}
            onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })}
            placeholder={isEditing ? 'Leave blank to keep current password' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {deletingUser?.name}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageCreatives;