import React, { useState } from 'react';
import { Box, Paper, List, ListItem, ListItemText, Typography, Divider, ListItemIcon, ListItemButton } from '@mui/material';
import { ClientProvider } from '../contexts/ClientContext';
import { RetainerProvider } from '../contexts/RetainerContext';
import { ProjectProvider } from '../contexts/ProjectContext';
import { UserProvider } from '../contexts/UserContext';
import { HoursProvider } from '../contexts/HoursContext';
import HourLogs from './HourLogs';  // Add this line

// Import individual report components
import ClientReports from './ClientReports';
import RetainerReports from './RetainerReports';
import ProjectReports from './ProjectReports';
import TeamReports from './TeamReports';

// Update these import lines to include the new icons
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import RepeatIcon from '@mui/icons-material/Repeat';
import BrushIcon from '@mui/icons-material/Brush';
import AccessTimeIcon from '@mui/icons-material/AccessTime';  // Add this line
import PersonIcon from '@mui/icons-material/Person';  // Add this import

const Reports = () => {
  const [selectedReport, setSelectedReport] = useState('Hour Logs');

  const renderReport = () => {
    switch (selectedReport) {
      case 'Clients':
        return <ClientReports />;
      case 'Retainers':
        return <RetainerReports />;
      case 'Projects':
        return <ProjectReports />;
      case 'Team':
        return <TeamReports />;
      case 'Hour Logs':
        return <HourLogs />;  // Add this case
      default:
        return <Typography>Select a report from the sidebar</Typography>;
    }
  };

  const iconColor = '#3498db';

  const reportItems = [
    { text: 'Hour Logs', icon: <AccessTimeIcon sx={{ color: iconColor }} /> },
    { text: 'Clients', icon: <BusinessIcon sx={{ color: iconColor }} /> },
    { text: 'Retainers', icon: <RepeatIcon sx={{ color: iconColor }} /> },
    { text: 'Projects', icon: <WorkIcon sx={{ color: iconColor }} /> },
    { text: 'Team', icon: <PersonIcon sx={{ color: iconColor }} /> },  // Update this line
  ];

  return (
    <ClientProvider>
      <RetainerProvider>
        <ProjectProvider>
          <UserProvider>
            <HoursProvider>
              <Box sx={{ 
                display: 'flex', 
                height: 'calc(100vh - 64px)', 
                position: 'fixed',
                top: 64,
                left: 0,
                right: 0,
                bottom: 0,
              }}>
                {/* Sidebar */}
                <Paper 
                  elevation={3} 
                  sx={{ 
                    width: 240, 
                    height: '100%',
                    overflowY: 'auto',
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>Reports</Typography>
                  <Divider />
                  <List sx={{ flexGrow: 1, padding: 0 }}>
                    {reportItems.map((item) => (
                      <ListItem 
                        key={item.text}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => setSelectedReport(item.text)}
                          selected={selectedReport === item.text}
                          sx={{
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '&.Mui-selected': {
                              backgroundColor: 'rgba(0, 0, 0, 0.08)',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                              },
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: 4,
                                bottom: 4,
                                width: 3,
                                backgroundColor: 'primary.main',
                                borderRadius: '0 2px 2px 0',
                              },
                            },
                          }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Paper>

                {/* Main content */}
                <Box sx={{ 
                  flexGrow: 1, 
                  backgroundColor: '#f5f5f5',
                  overflowY: 'auto',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  
                  <Box sx={{ flexGrow: 1, padding: 0 }}>
                    {renderReport()}
                  </Box>
                </Box>
              </Box>
            </HoursProvider>
          </UserProvider>
        </ProjectProvider>
      </RetainerProvider>
    </ClientProvider>
  );
};

export default Reports;