import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

function NavBar() {
  const { user, logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // If user is null, render a minimal version of the navbar
  if (!user) {
    return (
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Creative X Entertainment
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }

  const isAdminOrManager = user.permission === 'admin' || user.permission === 'manager';

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          CXE Time Tracker
        </Typography>
        <Box>
          {isAdminOrManager && (
            <>
              <Button color="inherit" component={RouterLink} to={`/dashboard`}>
                Dashboard
              </Button>
              <Button color="inherit" component={RouterLink} to="/manage">
                Manage
              </Button>
              <Button color="inherit" component={RouterLink} to="/reports">
                Reports
              </Button>
            </>
          )}
          <Button color="inherit" component={RouterLink} to="/log-hours">
            Log Hours
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;