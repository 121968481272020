import React, { useState, useMemo, useEffect } from 'react';
import { useHours } from '../contexts/HoursContext';
import { useProjects } from '../contexts/ProjectContext';
import { useUser } from '../contexts/UserContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useClients } from '../contexts/ClientContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Card, Avatar, IconButton, Tooltip, TextField, InputAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import moment from 'moment-timezone';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  '&:before': { display: 'none' },
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: '#F8F9FA',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 14px rgba(0,0,0,0.14)',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: ({ expanded }) => expanded ? alpha(theme.palette.primary.light, 0.2) : '#F8F9FA',
  color: theme.palette.primary.main,
  '&:hover': { backgroundColor: '#e3f2fd' },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

const TeamReports = () => {
  const [expandedTeamMember, setExpandedTeamMember] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { hoursLogs, loading: hoursLoading, fetchHoursLogs } = useHours();
  const { projects, loading: projectsLoading } = useProjects();
  const { users, loading: usersLoading } = useUser();
  const { retainers, loading: retainersLoading } = useRetainers();
  const { clients, loading: clientsLoading } = useClients();
  const { user } = useUser();
  const isAdmin = user && user.permission === 'admin';

  useEffect(() => {
    fetchHoursLogs();
  }, [fetchHoursLogs]);

  const teamData = useMemo(() => {
    if (hoursLoading || projectsLoading || usersLoading || retainersLoading || clientsLoading) {
      return null;
    }

    return users.map(teamMember => {
      const teamMemberHoursLogs = hoursLogs.filter(log => log.creativeId === teamMember.id);
      const activeProjects = projects.filter(project => !project.isDeleted);
      
      const totalHours = teamMemberHoursLogs.reduce((sum, log) => {
        const project = activeProjects.find(p => p.id === log.projectId);
        return project ? sum + log.hours : sum;
      }, 0);

      const totalAmount = teamMemberHoursLogs.reduce((sum, log) => {
        const project = activeProjects.find(p => p.id === log.projectId);
        return project ? sum + (log.hours * teamMember.rate) : sum;
      }, 0);

      const projectData = activeProjects.reduce((acc, project) => {
        const projectHours = teamMemberHoursLogs.filter(log => log.projectId === project.id);
        if (projectHours.length > 0) {
          const totalProjectHours = projectHours.reduce((sum, log) => sum + log.hours, 0);
          const totalProjectAmount = projectHours.reduce((sum, log) => sum + (log.hours * teamMember.rate), 0);
          const client = clients.find(c => c.id === project.clientId);
          const retainer = project.retainerId ? retainers.find(r => r.id === project.retainerId) : null;
          acc.push({
            project,
            hours: totalProjectHours,
            amount: totalProjectAmount,
            clientName: client ? client.name : 'Unknown Client',
            retainerName: retainer ? retainer.retainerName : null,
            projectType: project.projectType || (retainer ? 'retainer' : 'one-off'),
            logs: projectHours.map(log => ({
              ...log,
              amount: log.hours * teamMember.rate
            })).sort((a, b) => moment.utc(b.date).valueOf() - moment.utc(a.date).valueOf())
          });
        }
        return acc;
      }, []);

      const uniqueClients = [...new Set(projectData.map(p => p.clientName))];

      return {
        teamMember,
        totalHours,
        totalAmount,
        projectCount: projectData.length,
        clientCount: uniqueClients.length,
        projectData,
      };
    });
  }, [hoursLogs, projects, users, retainers, clients, hoursLoading, projectsLoading, usersLoading, retainersLoading, clientsLoading]);

  const filteredTeamData = useMemo(() => {
    if (!teamData) return [];
    return teamData.filter(data => 
      data.teamMember.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [teamData, searchTerm]);

  if (hoursLoading || projectsLoading || usersLoading || retainersLoading || clientsLoading) {
    return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
  }

  if (!teamData) {
    return null;
  }

  const formatDate = (dateString) => {
    return moment.utc(dateString).local().format('MMM D, YYYY');
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Team Reports
      </Typography>

      {/* Add search bar */}
      <Box sx={{ mt: 4, mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by team member name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {filteredTeamData.map(({ teamMember, totalHours, totalAmount, projectCount, clientCount, projectData }) => (
        <StyledAccordion
          key={teamMember.id}
          expanded={expandedTeamMember === teamMember.id}
          onChange={() => setExpandedTeamMember(expandedTeamMember === teamMember.id ? null : teamMember.id)}
        >
          <StyledAccordionSummary 
            expandIcon={<ExpandMoreIcon />}
            expanded={expandedTeamMember === teamMember.id}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#3498db', color: '#fff', mr: 2 }}>
                  <PersonIcon />
                </Avatar>
                <Typography variant="h6" sx={{ color: '#2c3e50' }}>{teamMember.name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ color: '#34495e', mr: 2 }}>
                  {projectCount} Projects | {clientCount} Clients | {totalHours.toFixed(2)} Hours
                </Typography>
              </Box>
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ p: 3, backgroundColor: '#fff' }}>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {[
                { icon: <AccessTimeIcon />, label: 'Total Hours', value: totalHours.toFixed(2), color: '#9b59b6' },
                { icon: <FolderIcon />, label: 'Projects', value: projectCount, color: '#3498db' },
                { icon: <BusinessIcon />, label: 'Clients', value: clientCount, color: '#f39c12' },
                ...(isAdmin ? [{ icon: <AttachMoneyIcon />, label: 'Total Amount', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount), color: '#2ecc71' }] : []),
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={isAdmin ? 3 : 4} key={index}>
                  <StyledCard>
                    <Avatar sx={{ bgcolor: item.color, mb: 1 }}>{item.icon}</Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{item.value}</Typography>
                    <Typography variant="body2" color="textSecondary">{item.label}</Typography>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 8, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>Project Details</Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Retainer Name</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Hours Logged</TableCell>
                    {isAdmin && <TableCell align="right">Amount</TableCell>}
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {projectData.flatMap(({ project, clientName, projectType, retainerName, logs }) => 
                    logs.map((log, index) => (
                      <TableRow key={`${project.id}-${index}`} hover>
                        <TableCell>{project.name}</TableCell>
                        <TableCell>{retainerName || 'N/A'}</TableCell>
                        <TableCell>{clientName}</TableCell>
                        <TableCell>
                          <Chip 
                            label={projectType === 'retainer' ? 'Retainer' : 'One-off'}
                            color={projectType === 'retainer' ? 'primary' : 'secondary'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{formatDate(log.date)}</TableCell>
                        <TableCell align="center">
                          {log.description && (
                            <Tooltip title={log.description} arrow>
                              <IconButton size="small">
                                <DescriptionIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">{log.hours.toFixed(2)}</TableCell>
                        {isAdmin && <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(log.amount)}</TableCell>}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default TeamReports;