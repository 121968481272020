import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import API_BASE_URL from '../config';

const ClientContext = createContext();

export function ClientProvider({ children }) {
  const [clients, setClients] = useState([]);

  // Wrap fetchClients with useCallback
  const fetchClients = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/clients`);
      if (!response.ok) throw new Error('Failed to fetch clients');
      const data = await response.json();
      // Optionally filter out soft-deleted clients here
      const activeClients = data.filter(client => !client.isDeleted);
      setClients(activeClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  // Fetch clients and optionally exclude soft-deleted clients
  const addClient = async (clientData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/clients`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientData),
      });
      if (!response.ok) throw new Error('Failed to add client');
      const newClient = await response.json();
      setClients([...clients, newClient]);
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  // Update an existing client
  const updateClient = async (id, updatedData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/clients/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update client: ${response.status} ${response.statusText}\n${errorText}`);
      }
      const updatedClient = await response.json();
      setClients(clients.map(client => client.id === id ? updatedClient : client));
    } catch (error) {
      console.error('Error updating client:', error);
      // You might want to handle this error in the UI as well
      throw error; // Re-throw the error so it can be handled by the component
    }
  };

  // Soft-delete a client
  const deleteClient = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/clients/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete client');
      // Optionally mark the client as deleted locally for better UX
      setClients(clients.map(client => client.id === id ? { ...client, isDeleted: true, name: `${client.name} (deleted)` } : client));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  return (
    <ClientContext.Provider value={{ clients, addClient, updateClient, deleteClient, fetchClients }}>
      {children}
    </ClientContext.Provider>
  );
}

export function useClients() {
  return useContext(ClientContext);
}