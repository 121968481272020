import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import API_BASE_URL from '../config';
import { useProjects } from './ProjectContext';  // Import useProjects hook
import { useHours } from './HoursContext';  // Add this import

const RetainerContext = createContext();

export function RetainerProvider({ children }) {
  const [retainers, setRetainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projects } = useProjects();  // Use the projects from ProjectContext
  const hoursContext = useHours();  // Change this line

  const fetchRetainers = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${API_BASE_URL}/retainers`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch retainers');
      }
      const data = await response.json();
      setRetainers(data);
    } catch (error) {
      console.error('Error fetching retainers:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRetainers();
  }, []);

  const addRetainer = async (retainerData) => {
    try {
      setError(null);
      const response = await fetch(`${API_BASE_URL}/retainers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(retainerData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add retainer');
      }
      const newRetainer = await response.json();
      setRetainers([...retainers, newRetainer]);
      return newRetainer;
    } catch (error) {
      console.error('Error adding retainer:', error);
      setError(error.message);
      throw error;
    }
  };

  const updateRetainer = async (id, retainerData) => {
    try {
      setError(null);
      const response = await fetch(`${API_BASE_URL}/retainers/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(retainerData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update retainer');
      }
      const updatedRetainer = await response.json();
      setRetainers(retainers.map(r => r.id === id ? updatedRetainer : r));
      return updatedRetainer;
    } catch (error) {
      console.error('Error updating retainer:', error);
      setError(error.message);
      throw error;
    }
  };

  const deleteRetainer = async (id) => {
    try {
      setError(null);
      const response = await fetch(`${API_BASE_URL}/retainers/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete retainer');
      }
      setRetainers(retainers.filter(retainer => retainer.id !== id));
    } catch (error) {
      console.error('Error deleting retainer:', error);
      setError(error.message);
      throw error;
    }
  };

  const getRetainerById = (id) => {
    const retainer = retainers.find(retainer => retainer.id === id);
    if (!retainer) {
      throw new Error(`Retainer with id ${id} not found`);
    }
    return retainer;
  };

  const getRetainerProjects = async (retainerId) => {
    try {
      setError(null);
      const response = await fetch(`${API_BASE_URL}/projects?retainerId=${retainerId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch retainer projects');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching retainer projects:', error);
      setError(error.message);
      throw error;
    }
  };

  // Replace associatedProjects with associatedProjectsAndHours
  const associatedProjectsAndHours = useMemo(() => {
    const projectMap = {};
    const hoursLogs = hoursContext?.hoursLogs || [];  // Add this safety check
    retainers.forEach(retainer => {
      const retainerProjects = projects.filter(project => 
        project.retainerId === retainer.id && !project.isDeleted
      );
      const hoursUsed = hoursLogs.reduce((total, log) => {
        const project = retainerProjects.find(p => p.id === log.projectId);
        return project ? total + log.hours : total;
      }, 0);
      projectMap[retainer.id] = {
        active: retainerProjects.filter(p => p.status === 'active').length,
        inactive: retainerProjects.filter(p => p.status !== 'active').length,
        hoursUsed
      };
    });
    return projectMap;
  }, [retainers, projects, hoursContext?.hoursLogs]);  // Update dependency array

  return (
    <RetainerContext.Provider value={{ 
      retainers, 
      loading, 
      error, 
      fetchRetainers, 
      addRetainer, 
      updateRetainer, 
      deleteRetainer,
      getRetainerById,
      getRetainerProjects,
      associatedProjectsAndHours  // Replace associatedProjects with this
    }}>
      {children}
    </RetainerContext.Provider>
  );
}

export function useRetainers() {
  const context = useContext(RetainerContext);
  if (context === undefined) {
    throw new Error('useRetainers must be used within a RetainerProvider');
  }
  return context;
}