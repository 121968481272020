import React, { createContext, useState, useContext, useEffect } from 'react';
import API_BASE_URL from '../config';

const HoursContext = createContext();

export function HoursProvider({ children }) {
  const [hoursLogs, setHoursLogs] = useState([]);
  const [hoursLoading, setHoursLoading] = useState(true);

  useEffect(() => {
    fetchHoursLogs();
  }, []);

  const fetchHoursLogs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/hours`);
      if (!response.ok) throw new Error('Failed to fetch hours');
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setHoursLogs(sortedData);
      setHoursLoading(false);
    } catch (error) {
      console.error('Error fetching hours:', error);
      setHoursLoading(false);
    }
  };

  const updateRetainerHours = async (retainerId, newHoursUsed) => {
    try {
      const response = await fetch(`${API_BASE_URL}/retainers/${retainerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hoursUsed: newHoursUsed }),
      });
      if (!response.ok) throw new Error('Failed to update retainer hours');
    } catch (error) {
      console.error('Error updating retainer hours:', error);
    }
  };

  const addHoursLog = async (hoursData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/hours`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(hoursData),
      });
      if (!response.ok) throw new Error('Failed to log hours');
      const newLog = await response.json();

      setHoursLogs([newLog, ...hoursLogs]);

      const projectResponse = await fetch(`${API_BASE_URL}/projects/${newLog.projectId}`);
      if (!projectResponse.ok) throw new Error('Failed to fetch project');
      const project = await projectResponse.json();
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed + newLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error logging hours:', error);
    }
  };

  const updateHoursLog = async (id, hoursData) => {
    try {
      const logResponse = await fetch(`${API_BASE_URL}/hours/${id}`);
      if (!logResponse.ok) throw new Error('Failed to fetch existing log');
      const existingLog = await logResponse.json();

      const response = await fetch(`${API_BASE_URL}/hours/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(hoursData),
      });
      if (!response.ok) throw new Error('Failed to update hours log');
      const updatedLog = await response.json();

      setHoursLogs(hoursLogs.map(log => log.id === id ? updatedLog : log));

      const projectResponse = await fetch(`${API_BASE_URL}/projects/${updatedLog.projectId}`);
      if (!projectResponse.ok) throw new Error('Failed to fetch project');
      const project = await projectResponse.json();
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed - existingLog.hours + updatedLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error updating hours log:', error);
    }
  };

  const deleteHoursLog = async (id) => {
    try {
      const logResponse = await fetch(`${API_BASE_URL}/hours/${id}`);
      if (!logResponse.ok) throw new Error('Failed to fetch existing log');
      const existingLog = await logResponse.json();

      const response = await fetch(`${API_BASE_URL}/hours/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete hours log');
      setHoursLogs(hoursLogs.filter(log => log.id !== id));

      const projectResponse = await fetch(`${API_BASE_URL}/projects/${existingLog.projectId}`);
      if (!projectResponse.ok) throw new Error('Failed to fetch project');
      const project = await projectResponse.json();
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed - existingLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error deleting hours log:', error);
    }
  };

  // New method to fetch hours by project ID
  const fetchHoursByProjectId = async (projectId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/hours/project/${projectId}`);
      if (!response.ok) throw new Error('Failed to fetch hours for project');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching hours for project:', error);
      throw error;
    }
  };

  // New method to fetch hours by creative ID
  const fetchHoursByCreativeId = async (creativeId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/hours/creative/${creativeId}`);
      if (!response.ok) throw new Error('Failed to fetch hours for creative');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching hours for creative:', error);
      throw error;
    }
  };

  return (
    <HoursContext.Provider value={{ 
      hoursLogs, 
      fetchHoursLogs, 
      addHoursLog, 
      updateHoursLog, 
      deleteHoursLog, 
      fetchHoursByProjectId,
      fetchHoursByCreativeId,
      loading: hoursLoading 
    }}>
      {children}
    </HoursContext.Provider>
  );
}

export function useHours() {
  return useContext(HoursContext);
}