import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useProjects } from '../contexts/ProjectContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useClients } from '../contexts/ClientContext';
import { useUser } from '../contexts/UserContext';
import { useHours } from '../contexts/HoursContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Card, Avatar, LinearProgress, Tooltip, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { lighten } from '@mui/material/styles';
import moment from 'moment-timezone';

// Styled components (reused from ClientReports and RetainerReports)
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  '&:before': { display: 'none' },
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: '#F8F9FA',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 14px rgba(0,0,0,0.14)',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: ({ expanded }) => expanded ? alpha(theme.palette.primary.light, 0.2) : '#F8F9FA',
  color: theme.palette.primary.main,
  '&:hover': { backgroundColor: '#e3f2fd' },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

const ProjectReports = () => {
  const [expandedProject, setExpandedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);

  const { projects, loading: projectsLoading, updateProject } = useProjects();
  const { retainers, loading: retainersLoading } = useRetainers();
  const { clients, loading: clientsLoading } = useClients();
  const { users, loading: usersLoading, fetchUsers } = useUser();
  const { hoursLogs, loading: hoursLoading, fetchHoursLogs } = useHours();
  const { user } = useUser();
  const isAdmin = user && user.permission === 'admin';

  useEffect(() => {
    fetchHoursLogs();
    if (users.length === 0 && !usersLoading) {
      fetchUsers();
    }
  }, [fetchHoursLogs, fetchUsers, users, usersLoading]);

  const formatDate = useCallback((dateString) => {
    if (!dateString) return 'N/A';
    return moment.utc(dateString).local().format('MMM D, YYYY');
  }, []);

  const isPastDue = (project) => {
    return project.status === 'active' && project.endDate && moment.utc().isAfter(moment.utc(project.endDate));
  };

  const calculateProjectMetrics = useCallback((project) => {
    const projectHours = hoursLogs
      .filter(log => log.projectId === project.id)
      .reduce((total, log) => total + log.hours, 0);
    
    const projectSpend = hoursLogs
      .filter(log => log.projectId === project.id)
      .reduce((total, log) => {
        const user = users.find(u => u.id === log.creativeId);
        const rate = user ? user.rate : 0;
        return total + (log.hours * rate);
      }, 0);

    const profit = Number(project.budget) - projectSpend;

    return { hours: projectHours, spend: projectSpend, profit };
  }, [hoursLogs, users]);

  const calculateRetainerProjectMetrics = useCallback((retainerId, currentProjectId) => {
    const retainerProjects = projects
      .filter(p => p.retainerId === retainerId)
      .map(p => {
        const projectLogs = hoursLogs.filter(log => log.projectId === p.id);
        const hours = projectLogs.reduce((total, log) => total + log.hours, 0);
        const spend = projectLogs.reduce((total, log) => {
          const user = users.find(u => u.id === log.creativeId);
          const rate = user ? user.rate : 0;
          return total + (log.hours * rate);
        }, 0);
        return { projectId: p.id, projectName: p.name, hours, spend };
      });

    // Move the current project to the beginning of the array
    const currentProjectIndex = retainerProjects.findIndex(p => p.projectId === currentProjectId);
    if (currentProjectIndex > 0) {
      const [currentProject] = retainerProjects.splice(currentProjectIndex, 1);
      retainerProjects.unshift(currentProject);
    }

    return retainerProjects;
  }, [projects, hoursLogs, users]);

  const projectData = useMemo(() => {
    if (projectsLoading || retainersLoading || clientsLoading || usersLoading || hoursLoading) {
      return null;
    }

    const activeProjects = projects.filter(project => !project.isDeleted);

    return activeProjects.map(project => {
      const client = clients.find(c => c.id === project.clientId);
      const retainer = project.retainerId ? retainers.find(r => r.id === project.retainerId) : null;
      const metrics = calculateProjectMetrics(project);

      let retainerProjectMetrics = [];
      if (retainer) {
        retainerProjectMetrics = calculateRetainerProjectMetrics(retainer.id, project.id);
      }

      return {
        ...project,
        clientName: client ? client.name : 'Unknown Client',
        retainer,
        retainerProjectMetrics,
        ...metrics
      };
    });
  }, [projects, retainers, clients, users, hoursLogs, projectsLoading, retainersLoading, clientsLoading, usersLoading, hoursLoading, calculateProjectMetrics, calculateRetainerProjectMetrics]);

  const filteredProjectData = useMemo(() => {
    if (!projectData) return [];
    return projectData.filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (project.retainer && project.retainer.retainerName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [projectData, searchTerm]);

  const isLoading = projectsLoading || retainersLoading || clientsLoading || usersLoading || hoursLoading;
  const hasData = projects.length > 0 && hoursLogs.length > 0;

  if (isLoading) {
    return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
  }

  if (!hasData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="text.secondary">
          No data available. Please check data loading.
        </Typography>
      </Box>
    );
  }

  if (!projectData) {
    return null;
  }

  const colorPalette = ['#3498db', '#2ecc71', '#f39c12', '#9b59b6', '#1abc9c', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#f1c40f', '#e67e22', '#95a5a6', '#d35400', '#bdc3c7'];

  const renderProgressBar = (projectMetrics, total, valueKey, tooltipLabel) => {
    let accumulatedPercentage = 0;
    const totalUsage = projectMetrics.reduce((sum, project) => sum + project[valueKey], 0);
    const scaleFactor = totalUsage > total ? total / totalUsage : 1;
    
    return (
      <Box sx={{ position: 'relative', height: 10, borderRadius: 5, backgroundColor: '#e0e0e0', overflow: 'hidden' }}>
        {projectMetrics.map((project, index) => {
          const rawPercentage = (project[valueKey] / total) * 100;
          const scaledPercentage = rawPercentage * scaleFactor;
          const startPercentage = accumulatedPercentage;
          accumulatedPercentage = Math.min(accumulatedPercentage + scaledPercentage, 100);
          
          return (
            <Tooltip
              key={project.projectId}
              title={`${project.projectName}: ${project[valueKey].toFixed(2)} ${tooltipLabel} (${rawPercentage.toFixed(1)}%)`}
              arrow
              placement="top"
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: `${startPercentage}%`,
                  width: `${accumulatedPercentage - startPercentage}%`,
                  height: '100%',
                  backgroundColor: colorPalette[index % colorPalette.length],
                  transition: 'width 0.3s ease-in-out',
                }}
              />
            </Tooltip>
          );
        })}
      </Box>
    );
  };

  const formatBudget = (budget) => {
    return budget ? `$${parseFloat(budget).toFixed(2)}` : 'N/A';
  };

  const handleEditProject = (project) => {
    setEditingProject({
      ...project,
      budget: project.projectType === 'one-off' ? project.budget.toString().replace(/[^0-9.]/g, '') : null
    });
    setIsEditModalOpen(true);
  };

  const handleUpdateProject = async () => {
    if (!editingProject) return;

    try {
      const updatedProject = {
        ...editingProject,
        budget: editingProject.projectType === 'one-off' ? parseFloat(editingProject.budget) : null
      };

      await updateProject(updatedProject.id, updatedProject);
      setIsEditModalOpen(false);
      // Optionally, refresh the projects data here
    } catch (error) {
      console.error('Failed to update project:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Project Reports
      </Typography>

      {/* Add search bar */}
      <Box sx={{ mt: 4, mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by project, client, or retainer name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {filteredProjectData.map((project) => (
        <StyledAccordion
          key={project.id}
          expanded={expandedProject === project.id}
          onChange={() => setExpandedProject(expandedProject === project.id ? null : project.id)}
        >
          <StyledAccordionSummary 
            expandIcon={<ExpandMoreIcon />}
            expanded={expandedProject === project.id}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Avatar sx={{ bgcolor: '#3498db', color: '#fff', mr: 2 }}>
                  <WorkIcon />
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ color: '#2c3e50', mr: 2 }}>{project.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(project.startDate)} - {formatDate(project.endDate)}
                  </Typography>
                </Box>
                <Chip 
                  label={project.projectType === 'retainer' 
                    ? `${project.retainer.retainerName} | ${project.clientName}`
                    : project.clientName}
                  size="small"
                  sx={{
                    backgroundColor: '#E3ECF7',
                    color: '#1F79D2',
                    fontWeight: 500,
                    borderRadius: '16px',
                    padding: '4px 8px',
                    ml: 2,
                    '& .MuiChip-label': {
                      padding: 0,
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip 
                  label={project.projectType}
                  size="small"
                  color={project.projectType === 'retainer' ? 'primary' : 'secondary'}
                  sx={{ mr: 1 }}
                />
                {isPastDue(project) ? (
                  <Chip 
                    label="Past Due"
                    size="small"
                    sx={{ 
                      backgroundColor: alpha('#e74c3c', 0.2), 
                      color: '#e74c3c'
                    }}
                  />
                ) : project.status === 'active' ? (
                  <Chip 
                    label="Active"
                    size="small"
                    sx={{ 
                      backgroundColor: alpha('#3498db', 0.2), 
                      color: '#3498db'
                    }}
                  />
                ) : (
                  <Tooltip title={`Completed on ${formatDate(project.updatedAt)}`} arrow>
                    <Chip 
                      label="Completed"
                      size="small"
                      sx={{ 
                        backgroundColor: alpha('#2ecc71', 0.2), 
                        color: '#2ecc71'
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ p: 3, backgroundColor: '#fff' }}>
            
            <Grid container spacing={3} sx={{ mb: 4, justifyContent: 'center' }}>
              {[
                { icon: <BusinessIcon />, label: 'Client', value: project.clientName, color: '#3498db' },
                ...(project.projectType === 'retainer' 
                  ? [{ icon: <WorkIcon />, label: 'Retainer Name', value: project.retainer.retainerName || 'N/A', color: '#f39c12' }] 
                  : []),
                { 
                  icon: <AccessTimeIcon />, 
                  label: project.projectType === 'retainer' ? 'Hours Used' : 'Hours Logged', 
                  value: project.projectType === 'retainer' 
                    ? `${Math.round(project.hours)} / ${Math.round(project.retainer.hoursPurchased)}`
                    : Math.round(project.hours),
                  color: '#9b59b6' 
                },
                ...(isAdmin ? [
                  { 
                    icon: <AttachMoneyIcon />, 
                    label: project.projectType === 'retainer' ? 'Retainer Budget' : 'Budget', 
                    value: formatBudget(project.projectType === 'retainer' ? project.retainer.amountPaid : project.budget), 
                    color: '#2ecc71' 
                  },
                  { 
                    icon: <AttachMoneyIcon />, 
                    label: project.projectType === 'retainer' ? 'Retainer Spent' : 'Spent', 
                    value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                      project.projectType === 'retainer' ? project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0) : project.spend
                    ), 
                    color: '#e74c3c' 
                  },
                ] : []),
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={isAdmin ? (project.projectType === 'retainer' ? 2.4 : 3) : 4} lg={isAdmin ? (project.projectType === 'retainer' ? 2.4 : 3) : 4} key={index}>
                  <StyledCard>
                    <Avatar sx={{ bgcolor: item.color, mb: 1 }}>{item.icon}</Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{item.value}</Typography>
                    <Typography variant="body2" color="textSecondary">{item.label}</Typography>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            {/* Progress Bars */}
            <Box sx={{ mt: 8, mb: 4 }}>
              {project.retainer ? (
                // Retainer-based project: Hours and Budget Usage
                <>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                    Retainer Hours Usage
                  </Typography>
                  {renderProgressBar(project.retainerProjectMetrics, Math.max(project.retainer.hoursPurchased, project.retainerProjectMetrics.reduce((total, p) => total + p.hours, 0)), 'hours', 'hours')}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 3 }}>
                    <Typography variant="body2" color="textSecondary">
                      {Math.round(project.hours)} hours used (this project)
                    </Typography>
                    <Typography variant="body2" color={project.retainerProjectMetrics.reduce((total, p) => total + p.hours, 0) > project.retainer.hoursPurchased ? 'warning.main' : 'textSecondary'}>
                      {project.retainerProjectMetrics.reduce((total, p) => total + p.hours, 0) > project.retainer.hoursPurchased
                        ? `${Math.round(project.retainerProjectMetrics.reduce((total, p) => total + p.hours, 0) - project.retainer.hoursPurchased)} hours over (total)`
                        : `${Math.round(project.retainer.hoursPurchased - project.retainerProjectMetrics.reduce((total, p) => total + p.hours, 0))} hours remaining (total)`
                      }
                    </Typography>
                  </Box>

                  {isAdmin && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                        Retainer Budget Usage
                      </Typography>
                      {renderProgressBar(project.retainerProjectMetrics, Math.max(project.retainer.amountPaid, project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0)), 'spend', 'USD')}
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                        <Typography variant="body2" color="textSecondary">
                          ${Math.round(project.spend)} spent (this project)
                        </Typography>
                        <Typography variant="body2" color={project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0) > project.retainer.amountPaid ? 'warning.main' : 'textSecondary'}>
                          {project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0) > project.retainer.amountPaid
                            ? `$${Math.round(project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0) - project.retainer.amountPaid)} over budget (total)`
                            : `$${Math.round(project.retainer.amountPaid - project.retainerProjectMetrics.reduce((total, p) => total + p.spend, 0))} remaining (total)`
                          }
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                // One-off project: Budget Usage (only for admins)
                isAdmin && (
                  <>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                      Budget Usage
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={Math.min((project.spend / project.budget) * 100, 100)} 
                      sx={{ 
                        height: 10, 
                        borderRadius: 5,
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: project.spend > project.budget ? '#f39c12' : '#3498db',
                        },
                      }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="body2" color="textSecondary">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(project.spend)} spent
                      </Typography>
                      <Typography variant="body2" color={project.spend > project.budget ? 'warning.main' : 'textSecondary'}>
                        {project.spend > project.budget 
                          ? `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(project.spend - project.budget)} over budget`
                          : `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(project.budget - project.spend)} remaining`
                        }
                      </Typography>
                    </Box>
                  </>
                )
              )}
            </Box>

            {/* Hours Logged Table */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>Hours Logged</Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell align="right">Hours</TableCell>
                    {isAdmin && <TableCell align="right">Cost</TableCell>}
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {hoursLogs
                    .filter(log => log.projectId === project.id)
                    .sort((a, b) => moment.utc(b.date).valueOf() - moment.utc(a.date).valueOf())
                    .map(log => {
                      const user = users.find(u => u.id === log.creativeId);
                      return (
                        <TableRow key={log.id} hover>
                          <TableCell>{user ? user.name : 'Unknown User'}</TableCell>
                          <TableCell align="right">{Math.round(log.hours)}</TableCell>
                          {isAdmin && (
                            <TableCell align="right">
                              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(Math.round(log.hours * (user ? user.rate : 0)))}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {formatDate(log.date)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </AccordionDetails>
        </StyledAccordion>
      ))}

      {/* Edit Project Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Project Name"
            value={editingProject?.name || ''}
            onChange={(e) => setEditingProject({ ...editingProject, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Client"
            value={editingProject?.clientName || ''}
            fullWidth
            margin="normal"
            disabled
          />
          {editingProject?.projectType === 'one-off' && (
            <TextField
              label="Budget"
              type="text"
              value={editingProject?.budget || ''}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9.]/g, '');
                // Ensure only one decimal point
                const parts = value.split('.');
                if (parts.length > 1) {
                  value = parts[0] + '.' + parts[1].slice(0, 2);
                }
                setEditingProject({ ...editingProject, budget: value });
              }}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          )}
          {/* Add other fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateProject} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectReports;