import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Avatar,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Business as BusinessIcon } from '@mui/icons-material';
import { useClients } from '../contexts/ClientContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useProjects } from '../contexts/ProjectContext';
import { useHours } from '../contexts/HoursContext';
import { styled, alpha } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

function ManageClients() {
  const location = useLocation();
  const { clients, addClient, updateClient, deleteClient } = useClients();
  const { retainers } = useRetainers();
  const { projects } = useProjects();
  const { hoursLogs } = useHours();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clientName, setClientName] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientStatus, setClientStatus] = useState('active');
  const [deletingClient, setDeletingClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('Clients:', clients);
    console.log('Retainers:', retainers);
    console.log('Projects:', projects);
    console.log('Hours Logs:', hoursLogs);
  }, [clients, retainers, projects, hoursLogs]);

  useEffect(() => {
    if (
      clients !== undefined &&
      retainers !== undefined &&
      projects !== undefined &&
      hoursLogs !== undefined
    ) {
      setIsLoading(false);
    }
  }, [clients, retainers, projects, hoursLogs]);

  const handleOpenDialog = (client = null) => {
    setSelectedClient(client);
    setClientName(client ? client.name : '');
    setClientStatus(client ? client.status : 'active');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setClientName('');
    setClientStatus('active');
    setSelectedClient(null);
  };

  const handleSaveClient = async () => {
    if (selectedClient) {
      try {
        await updateClient(selectedClient.id, { ...selectedClient, name: clientName, status: clientStatus });
        // Handle successful update
      } catch (error) {
        console.error('Failed to update client:', error);
        // Show an error message to the user
      }
    } else {
      await addClient({ name: clientName, status: clientStatus });
    }
    handleCloseDialog();
  };

  // Open the Delete confirmation dialog
  const handleOpenDeleteDialog = (client) => {
    setDeletingClient(client);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeletingClient(null);
  };

  // Handle soft-delete of a client
  const handleDeleteClient = () => {
    deleteClient(deletingClient.id);
    handleCloseDeleteDialog();
  };

  // Toggle client status (active/inactive)
  const handleToggleStatus = async (client) => {
    const newStatus = client.status === 'active' ? 'inactive' : 'active';
    await updateClient(client.id, { ...client, status: newStatus });
  };

  // Filter clients based on search term
  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const activeClients = filteredClients.filter(client => client.status === 'active' && !client.isDeleted);
  const inactiveClients = filteredClients.filter(client => client.status === 'inactive' && !client.isDeleted);

  // Update the clientMetrics calculation
  const clientMetrics = useMemo(() => {
    const metrics = filteredClients.map(client => {
      const clientProjects = projects.filter(project => project.clientId === client.id && !project.isDeleted);
      const clientRetainers = retainers.filter(retainer => retainer.clientId === client.id && !retainer.isDeleted);
      const clientHours = hoursLogs.filter(log => 
        clientProjects.some(project => project.id === log.projectId)
      ).reduce((total, log) => total + log.hours, 0);

      return {
        ...client,
        projectCount: clientProjects.length,
        retainerCount: clientRetainers.length,
        totalHours: clientHours
      };
    });

    // Sort clients: active first, then inactive
    return metrics.sort((a, b) => {
      if (a.status === 'active' && b.status === 'inactive') return -1;
      if (a.status === 'inactive' && b.status === 'active') return 1;
      return a.name.localeCompare(b.name); // Sort alphabetically within each group
    });
  }, [filteredClients, projects, retainers, hoursLogs]);

  const handleAddClient = async (clientData) => {
    await addClient({
      name: clientData.name,
      status: clientData.status || 'active', // Default to active if not specified
    });
  };

  const handleUpdateClient = async (id, updatedData) => {
    try {
      await updateClient(id, {
        name: updatedData.name,
        status: updatedData.status,
      });
      // Handle successful update
    } catch (error) {
      console.error('Failed to update client:', error);
      // Show an error message to the user
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Box sx={{ mt: 4, minHeight: '100vh', p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
          Manage Clients
        </Typography>

        <Grid container spacing={3} sx={{ mb: 4, justifyContent: 'center' }}>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}>
                  <BusinessIcon />
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{clients.length}</Typography>
                  <Typography variant="body2" color="textSecondary">Total Clients</Typography>
                </Box>
              </Box>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#2ecc71', mr: 2 }}>
                  <BusinessIcon />
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{activeClients.length}</Typography>
                  <Typography variant="body2" color="textSecondary">Active Clients</Typography>
                </Box>
              </Box>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#e74c3c', mr: 2 }}>
                  <BusinessIcon />
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{inactiveClients.length}</Typography>
                  <Typography variant="body2" color="textSecondary">Inactive Clients</Typography>
                </Box>
              </Box>
            </StyledCard>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{ mb: 2 }}
          >
            Add New Client
          </Button>
          <TextField
            variant="outlined"
            placeholder="Search clients"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: '300px' }}
          />
        </Box>

        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>Client Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Projects</TableCell>
                <TableCell align="center">Retainers</TableCell>
                <TableCell align="center">Total Hours</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {clientMetrics.map((client, index) => (
                <React.Fragment key={client.id}>
                  {index > 0 && clientMetrics[index - 1].status === 'active' && client.status === 'inactive' && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ backgroundColor: alpha('#bdc3c7', 0.2), py: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#7f8c8d' }}>
                          Inactive Clients
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow hover>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                        {client.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={client.status === 'active' ? 'Active' : 'Inactive'} 
                        size="small" 
                        sx={{ 
                          backgroundColor: client.status === 'active' 
                            ? alpha('#3498db', 0.2) 
                            : alpha('#bdc3c7', 0.2), 
                          color: client.status === 'active' ? '#3498db' : '#7f8c8d'
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{client.projectCount}</TableCell>
                    <TableCell align="center">{client.retainerCount}</TableCell>
                    <TableCell align="center">{client.totalHours.toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(client)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleOpenDeleteDialog(client)}>
                        <DeleteIcon />
                      </IconButton>
                      <Button
                        size="small"
                        onClick={() => handleToggleStatus(client)}
                        variant="outlined"
                        sx={{ ml: 2 }}
                      >
                        {client.status === 'active' ? 'Mark Inactive' : 'Make Active'}
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      {/* Add/Edit Client Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedClient ? 'Edit Client' : 'Add New Client'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Client Name"
            type="text"
            fullWidth
            variant="outlined"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="client-status-label">Status</InputLabel>
            <Select
              labelId="client-status-label"
              id="client-status"
              value={clientStatus}
              onChange={(e) => setClientStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveClient} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {deletingClient?.name}? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteClient} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ManageClients;