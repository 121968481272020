import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  ListSubheader,
  CircularProgress,
  Typography,
  Chip,
  Tooltip,
  InputAdornment,
  Card,
  Avatar
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Description as DescriptionIcon, Search as SearchIcon } from '@mui/icons-material';
import { useHours } from '../contexts/HoursContext';
import { useProjects } from '../contexts/ProjectContext';
import { useUser } from '../contexts/UserContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useClients } from '../contexts/ClientContext';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WorkIcon from '@mui/icons-material/Work';
import RepeatIcon from '@mui/icons-material/Repeat';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import moment from 'moment-timezone';

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '60vh',
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'auto',
  '& .MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[50],
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
}));

const ProjectTypeIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: theme.spacing(1),
  },
}));

const TruncatedText = styled(Typography)({
  maxWidth: 150,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SummaryCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center content horizontally
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  flex: 1, // Allow cards to grow and take equal width
  minWidth: '250px', // Set a minimum width for the cards
}));

function HourLogs() {
  const { hoursLogs, updateHoursLog, deleteHoursLog, loading: hoursLoading, error: hoursError } = useHours();
  const { projects, fetchProjects, loading: projectsLoading, error: projectsError } = useProjects();
  const { users, fetchUsers, user, loading: usersLoading, error: usersError } = useUser();
  const { clients } = useClients();
  const { retainers, fetchRetainers } = useRetainers();

  const isAdmin = user && user.permission === 'admin';
  const isManager = user && user.permission === 'manager';

  const userRates = useMemo(() => 
    users.reduce((acc, user) => ({ ...acc, [user.id]: user.rate }), {}),
    [users]
  );

  const calculateSpend = useMemo(() => {
    return (userId, hoursWorked) => {
      const rate = userRates[userId];
      if (!rate) return 0;
      return rate * hoursWorked;
    };
  }, [userRates]);

  const getProjectStatus = useCallback((project) => {
    if (!project) return { color: 'grey', text: '(Deleted)' };
    if (project.isDeleted) return { color: 'grey', text: '(Deleted)' };
    if (project.status === 'inactive') return { color: 'error' };
    return { color: 'success', text: '' };
  }, []);

  const getProjectName = useCallback((projectId) => {
    const project = projects.find(p => p.id === projectId);
    if (!project) return 'Unknown Project';
    const status = getProjectStatus(project);
    const client = clients.find(c => c.id === project.clientId);
    const retainer = retainers.find(r => r.id === project.retainerId);
    
    return (
      <Box display="flex" alignItems="center">
        <FiberManualRecordIcon color={status.color} sx={{ fontSize: 12, mr: 1 }} />
        <TruncatedText variant="body2">
          {project.name} {status.text}
        </TruncatedText>
        {project.projectType === 'retainer' && retainer && (
          <StyledChip label={`${retainer.retainerName} - ${client ? client.name : 'Unknown Client'}`} size="small" />
        )}
        {project.projectType !== 'retainer' && client && (
          <StyledChip label={client.name} size="small" />
        )}
      </Box>
    );
  }, [projects, clients, retainers, getProjectStatus]);

  const sortedLogs = useMemo(() => 
    [...hoursLogs].sort((a, b) => new Date(b.date) - new Date(a.date)),
    [hoursLogs]
  );

  const groupProjectsByStatus = () => {
    const grouped = {
      active: [],
      inactive: [],
      deleted: []
    };

    projects.forEach(project => {
      if (project.isDeleted) {
        grouped.deleted.push(project);
      } else if (project.status === 'inactive') {
        grouped.inactive.push(project);
      } else {
        grouped.active.push(project);
      }
    });

    return grouped;
  };

  const groupedProjects = useMemo(() => groupProjectsByStatus(), [projects, groupProjectsByStatus]);

  useEffect(() => {
    if (!projects.length) fetchProjects();
    if (!users.length) fetchUsers();
    if (!retainers.length) fetchRetainers();
  }, [projects.length, users.length, retainers.length, fetchProjects, fetchUsers, fetchRetainers]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editingLog, setEditingLog] = useState(null);
  const [deletingLogId, setDeletingLogId] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [userId, setUserId] = useState('');
  const [hours, setHours] = useState('');
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [timePeriod, setTimePeriod] = useState('all');
  const [editDate, setEditDate] = useState('');

  const getDateRange = useCallback((period) => {
    const now = moment().startOf('day');
    switch (period) {
      case 'today':
        return { start: now, end: now.clone().endOf('day') };
      case 'thisWeek':
        return { start: now.clone().startOf('isoWeek'), end: now.clone().endOf('isoWeek') };
      case 'lastWeek':
        return { 
          start: now.clone().subtract(1, 'week').startOf('isoWeek'), 
          end: now.clone().subtract(1, 'week').endOf('isoWeek') 
        };
      case 'thisMonth':
        return { start: now.clone().startOf('month'), end: now.clone().endOf('month') };
      case 'lastMonth':
        return { 
          start: now.clone().subtract(1, 'month').startOf('month'), 
          end: now.clone().subtract(1, 'month').endOf('month') 
        };
      case 'thisQuarter':
        return { start: now.clone().startOf('quarter'), end: now.clone().endOf('quarter') };
      case 'lastQuarter':
        return { 
          start: now.clone().subtract(1, 'quarter').startOf('quarter'), 
          end: now.clone().subtract(1, 'quarter').endOf('quarter') 
        };
      case 'yearToDate':
        return { start: now.clone().startOf('year'), end: now };
      case 'all':
      default:
        return null;
    }
  }, []);

  const filteredLogs = useMemo(() => {
    return sortedLogs.filter(log => {
      const project = projects.find(p => p.id === log.projectId);
      
      // Exclude logs for deleted projects
      if (!project || project.isDeleted) {
        return false;
      }

      const client = clients.find(c => c.id === project.clientId);
      const user = users.find(u => u.id === log.creativeId);
      const retainer = retainers.find(r => r.id === project.retainerId);
      
      const searchString = `
        ${project.name || ''}
        ${client?.name || ''}
        ${user?.name || ''}
        ${retainer?.retainerName || ''}
        ${log.description || ''}
      `.toLowerCase();

      const matchesSearch = searchString.includes(searchTerm.toLowerCase());

      const dateRange = getDateRange(timePeriod);
      const logDate = moment(log.date).startOf('day');
      
      const matchesTimePeriod = timePeriod === 'all' || (dateRange && logDate.isBetween(dateRange.start, dateRange.end, 'day', '[]'));

      return matchesSearch && matchesTimePeriod;
    });
  }, [sortedLogs, projects, clients, users, retainers, searchTerm, timePeriod, getDateRange]);

  const summary = useMemo(() => {
    if (!isAdmin && !isManager) return null;

    const totalHours = filteredLogs.reduce((sum, log) => sum + (Number(log.hours) || 0), 0);
    const uniqueProjects = new Set(filteredLogs.map(log => log.projectId)).size;
    
    if (isAdmin) {
      const totalAmount = filteredLogs.reduce((sum, log) => sum + calculateSpend(log.creativeId, Number(log.hours) || 0), 0);
      return { totalHours, uniqueProjects, totalAmount };
    }
    
    return { totalHours, uniqueProjects };
  }, [filteredLogs, isAdmin, isManager, calculateSpend]);

  const formatNumber = (num) => {
    return (Number(num) || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const formatDate = (dateString) => {
    return moment.utc(dateString).local().format('ddd, MMM D, YYYY');
  };

  const handleOpenDialog = useCallback((log) => {
    setEditingLog(log);
    setProjectId(log.projectId);
    setUserId(log.creativeId);
    setHours(log.hours);
    setDescription(log.description);
    const formattedDate = moment(log.date).format('YYYY-MM-DD');
    setEditDate(formattedDate);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setEditingLog(null);
  }, []);

  const handleSave = useCallback(async () => {
    const updatedLog = {
      projectId,
      creativeId: userId,
      hours: Number(hours) || 0,
      description,
      date: moment(editDate).utc().format(),
    };

    await updateHoursLog(editingLog.id, updatedLog);
    handleCloseDialog();
  }, [projectId, userId, hours, description, editDate, editingLog, updateHoursLog, handleCloseDialog]);

  const handleOpenDeleteDialog = (id) => {
    setDeletingLogId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeletingLogId(null);
  };

  const handleConfirmDelete = async () => {
    if (deletingLogId) {
      await deleteHoursLog(deletingLogId);
      handleCloseDeleteDialog();
    }
  };

  if (hoursLoading || projectsLoading || usersLoading) {
    return (
      <Container maxWidth={false} disableGutters sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (hoursError || projectsError || usersError) {
    return (
      <Container maxWidth={false} disableGutters sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography color="error">Error loading data. Please try again.</Typography>
      </Container>
    );
  }

  const noDataMessage = (
    <Paper sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6">
        {hoursLogs.length === 0 ? "There are currently no hours logged." : "No results found. Please try a different search or time period."}
      </Typography>
    </Paper>
  );

  const LogRow = React.memo(({ log, isAdmin, calculateSpend }) => {
    const amountSpent = calculateSpend(log.creativeId, log.hours);
    
    return (
      <TableRow>
        <TableCell>{getProjectName(log.projectId)}</TableCell>
        <TableCell>
          <ProjectTypeIcon>
            {projects.find(p => p.id === log.projectId)?.projectType === 'retainer' ? <RepeatIcon color="primary" /> : <WorkIcon color="secondary" />}
            <Typography variant="body2">{projects.find(p => p.id === log.projectId)?.projectType === 'retainer' ? 'Retainer' : 'One-off'}</Typography>
          </ProjectTypeIcon>
        </TableCell>
        <TableCell>
          <TruncatedText variant="body2">
            {users.find(user => user.id === log.creativeId)?.name || 'Unknown'}
          </TruncatedText>
        </TableCell>
        <TableCell align="right">{formatNumber(log.hours)}</TableCell>
        <TableCell align="center">
          {log.description && (
            <Tooltip title={log.description} arrow>
              <IconButton size="small">
                <DescriptionIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{formatDate(log.date)}</TableCell>
        {isAdmin && (
          <TableCell align="right">
            ${formatNumber(amountSpent.toFixed(2))}
          </TableCell>
        )}
        <TableCell>
          <IconButton onClick={() => handleOpenDialog(log)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
          {isAdmin && (
            <IconButton onClick={() => handleOpenDeleteDialog(log.id)} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Container maxWidth={false} disableGutters sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Hour Logs
      </Typography>

      <Box sx={{ mt: 4, mb: 2, display: 'flex', gap: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by project, client, user name, retainer name, or description"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <Select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            displayEmpty
          >
            <MenuItem value="all">All Time</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="thisWeek">This Week</MenuItem>
            <MenuItem value="lastWeek">Last Week</MenuItem>
            <MenuItem value="thisMonth">This Month</MenuItem>
            <MenuItem value="lastMonth">Last Month</MenuItem>
            <MenuItem value="thisQuarter">This Quarter</MenuItem>
            <MenuItem value="lastQuarter">Last Quarter</MenuItem>
            <MenuItem value="yearToDate">Year To Date</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      {(isAdmin || isManager) && summary && (
        <Box sx={{ display: 'flex', gap: 2, mb: 4, flexWrap: 'wrap', justifyContent: 'center' }}>
          <SummaryCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#9b59b6', mr: 2 }}><AccessTimeIcon /></Avatar>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                  {formatNumber(summary.totalHours)}
                </Typography>
                <Typography variant="body2" color="textSecondary">Total Hours</Typography>
              </Box>
            </Box>
          </SummaryCard>
          <SummaryCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}><FolderIcon /></Avatar>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{summary.uniqueProjects}</Typography>
                <Typography variant="body2" color="textSecondary">Total Projects</Typography>
              </Box>
            </Box>
          </SummaryCard>
          {isAdmin && (
            <SummaryCard>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#2ecc71', mr: 2 }}><AttachMoneyIcon /></Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>${formatNumber(summary.totalAmount.toFixed(2))}</Typography>
                  <Typography variant="body2" color="textSecondary">Total Amount</Typography>
                </Box>
              </Box>
            </SummaryCard>
          )}
        </Box>
      )}

      {filteredLogs.length === 0 ? noDataMessage : (
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                <TableCell>Project Type</TableCell>
                <TableCell>User</TableCell>
                <TableCell align="right">Hours Worked</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                {isAdmin && (
                  <TableCell align="right">Amount Spent</TableCell>
                )}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs.map((log) => (
                <LogRow key={log.id} log={log} isAdmin={isAdmin} calculateSpend={calculateSpend} />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}

      {/* Edit Hours Log Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Hours Log</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="project-select-label">Project</InputLabel>
            <Select
              labelId="project-select-label"
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
            >
              {Object.entries(groupedProjects).map(([status, projectList]) => [
                <ListSubheader key={status}>{status.charAt(0).toUpperCase() + status.slice(1)}</ListSubheader>,
                ...projectList.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))
              ])}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="user-select-label">User</InputLabel>
            <Select
              labelId="user-select-label"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="Hours Worked"
            type="number"
            fullWidth
            value={hours}
            onChange={(e) => setHours(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {isAdmin && (
            <TextField
              margin="dense"
              label="Amount Spent"
              type="text"
              fullWidth
              value={`$${formatNumber(calculateSpend(userId, hours).toFixed(2))}`}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          
          <TextField
            margin="dense"
            label="Date"
            type="date"
            fullWidth
            value={editDate}
            onChange={(e) => setEditDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: moment().format('YYYY-MM-DD'), // Set max date to today
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this hours log? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default HourLogs;