import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';

const ProjectContext = createContext();

export function ProjectProvider({ children }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      // Add any authentication headers here, e.g.:
      // 'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });

  const fetchProjects = useCallback(async (status, clientId) => {
    setLoading(true);
    setError(null);
    try {
      let url = '/projects';
      const params = new URLSearchParams();
      if (status) params.append('status', status);
      if (clientId) params.append('clientId', clientId);
      if (params.toString()) url += `?${params.toString()}`;

      const response = await api.get(url);
      console.log('Fetched projects:', response.data);
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error.response?.data?.message || 'Failed to fetch projects');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const addProject = async (projectData) => {
    setLoading(true);
    setError(null);
    try {
      // Validate project type and retainer ID
      if (projectData.projectType === 'one-off') {
        projectData.retainerId = null;  // Explicitly set to null for one-off projects
      } else if (projectData.projectType === 'retainer' && !projectData.retainerId) {
        throw new Error('Retainer projects must have a retainer ID');
      }

      // Handle budget based on project type
      if (projectData.projectType === 'retainer') {
        projectData.budget = null;
      } else if (projectData.projectType === 'one-off') {
        // Ensure budget is a number (in cents) for one-off projects
        if (typeof projectData.budget === 'string' && projectData.budget !== '') {
          projectData.budget = Math.round(parseFloat(projectData.budget) * 100);
        } else if (projectData.budget === '') {
          projectData.budget = null;
        }
      }

      const response = await api.post('/projects', projectData);
      setProjects(prevProjects => [...prevProjects, response.data]);
      return response.data;
    } catch (error) {
      console.error('Error adding project:', error);
      setError(error.response?.data?.message || error.message || 'Failed to add project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateProject = async (id, projectData) => {
    setLoading(true);
    setError(null);
    try {
      // Validate project type and retainer ID
      if (projectData.projectType === 'one-off') {
        projectData.retainerId = null;  // Explicitly set to null for one-off projects
      } else if (projectData.projectType === 'retainer' && !projectData.retainerId) {
        throw new Error('Retainer projects must have a retainer ID');
      }

      // Handle budget based on project type
      if (projectData.projectType === 'retainer') {
        projectData.budget = null;
      } else if (projectData.projectType === 'one-off') {
        // Ensure budget is a number (in cents) for one-off projects
        if (typeof projectData.budget === 'string' && projectData.budget !== '') {
          projectData.budget = Math.round(parseFloat(projectData.budget) * 100);
        } else if (projectData.budget === '') {
          projectData.budget = null;
        }
      }

      console.log('Project data before update:', projectData);
      const response = await api.put(`/projects/${id}`, projectData);
      console.log('Response from server:', response.data);

      setProjects(prevProjects => 
        prevProjects.map(project => {
          if (project.id === id) {
            console.log('Updated project:', response.data);
            return response.data;
          }
          return project;
        })
      );
      await fetchProjects(); // Force a refresh of all projects
      return response.data;
    } catch (error) {
      console.error('Error updating project:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.message || error.message || 'Failed to update project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/projects/${id}`);
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project.id === id ? {...project, isDeleted: true, status: 'inactive'} : project
        )
      );
    } catch (error) {
      console.error('Error deleting project:', error);
      setError(error.response?.data?.message || 'Failed to delete project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getProjectById = useCallback((id) => {
    return projects.find(project => project.id === id);
  }, [projects]);

  const getProjectsByClient = useCallback((clientId) => {
    return projects.filter(project => project.clientId === clientId);
  }, [projects]);

  const updateMultipleProjects = async (projectUpdates) => {
    setLoading(true);
    setError(null);
    try {
      const updatedProjects = projectUpdates.map(project => {
        if (project.projectType === 'retainer') {
          return { ...project, budget: null };
        } else if (project.projectType === 'one-off') {
          return {
            ...project,
            budget: typeof project.budget === 'string' ? parseFloat(project.budget) : project.budget,
            retainerId: null  // Explicitly set to null for one-off projects
          };
        }
        return project;
      });

      const response = await api.put('/projects/batch', { projects: updatedProjects });
      setProjects(prevProjects => 
        prevProjects.map(project => {
          const updatedProject = response.data.find(p => p.id === project.id);
          return updatedProject ? updatedProject : project;
        })
      );
      return response.data;
    } catch (error) {
      console.error('Error updating multiple projects:', error);
      setError(error.response?.data?.message || 'Failed to update projects');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    projects,
    loading,
    error,
    fetchProjects,
    addProject,
    updateProject,
    deleteProject,
    getProjectById,
    getProjectsByClient,
    updateMultipleProjects,
  };

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  );
}

export function useProjects() {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
}