import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useClients } from '../contexts/ClientContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useProjects } from '../contexts/ProjectContext';
import { useUser } from '../contexts/UserContext';
import { useHours } from '../contexts/HoursContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Card, CardContent, Avatar, TextField, InputAdornment, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import moment from 'moment-timezone';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  '&:before': { display: 'none' },
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: '#F8F9FA',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 14px rgba(0,0,0,0.14)',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: ({ expanded }) => expanded ? alpha(theme.palette.primary.light, 0.2) : '#F8F9FA',
  color: theme.palette.primary.main,
  '&:hover': { backgroundColor: '#e3f2fd' }, // Light blue color
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

const ClientReports = () => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { clients, loading: clientsLoading } = useClients();
  const { retainers, loading: retainersLoading, associatedProjectsAndHours } = useRetainers();
  const { projects, loading: projectsLoading } = useProjects();
  const { users, loading: usersLoading, fetchUsers } = useUser();
  const { hoursLogs, loading: hoursLoading, fetchHoursLogs } = useHours();
  const { user } = useUser();
  const isAdmin = user && user.permission === 'admin';
  const isManager = user && user.permission === 'manager';

  useEffect(() => {
    fetchHoursLogs();
  }, [fetchHoursLogs]);

  useEffect(() => {
    if (users.length === 0 && !usersLoading) {
      fetchUsers();
    }
  }, [users, usersLoading, fetchUsers]);

  const formatDate = useCallback((dateString) => {
    if (!dateString) return 'N/A';
    return moment.utc(dateString).local().format('MMM D, YYYY');
  }, []);

  const isPastDue = (item) => {
    return item.status === 'active' && item.endDate && moment.utc().isAfter(moment.utc(item.endDate));
  };

  const calculateProjectSpend = useCallback((projectId) => {
    const projectHoursLogs = hoursLogs.filter(log => log.projectId === projectId);
    
    return projectHoursLogs.reduce((total, log) => {
      const user = users.find(u => u.id === log.creativeId);
      const rate = user ? user.rate : 0;
      return total + (log.hours * rate);
    }, 0);
  }, [hoursLogs, users]);

  const calculateProjectMetrics = useCallback((project) => {
    const projectHours = hoursLogs
      .filter(log => log.projectId === project.id)
      .reduce((total, log) => total + log.hours, 0);
    
    const projectSpend = calculateProjectSpend(project.id);
    const profit = Number(project.budget) - projectSpend;

    return { hours: projectHours, spend: projectSpend, profit };
  }, [hoursLogs, calculateProjectSpend]);

  const calculateRetainerMetrics = useCallback((retainer, projectSpends) => {
    const totalSpent = projectSpends.reduce((total, { spend }) => total + spend, 0);
    const profit = Number(retainer.amountPaid) - totalSpent;
    return { totalSpent, profit };
  }, []);

  const clientData = useMemo(() => {
    if (clientsLoading || retainersLoading || projectsLoading || usersLoading || hoursLoading) {
      return null;
    }

    console.log('Calculating clientData');
    console.log('Clients:', clients);
    console.log('Retainers:', retainers);
    console.log('Projects:', projects);
    console.log('Users:', users);
    console.log('HoursLogs:', hoursLogs);

    const activeClients = clients.filter(client => !client.isDeleted);
    const activeRetainers = retainers.filter(retainer => !retainer.isDeleted);
    const activeProjects = projects.filter(project => !project.isDeleted);

    return activeClients.map(client => {
      const clientOneOffProjects = activeProjects.filter(project => project.clientId === client.id && !project.retainerId)
        .map(project => ({
          ...project,
          ...calculateProjectMetrics(project)
        }));
      const clientRetainers = activeRetainers.filter(retainer => retainer.clientId === client.id);
      
      const retainerData = clientRetainers.map(retainer => {
        const retainerProjects = activeProjects.filter(project => project.retainerId === retainer.id);
        const { hoursUsed } = associatedProjectsAndHours[retainer.id] || { hoursUsed: 0 };
        
        const projectSpends = retainerProjects.map(project => {
          const projectHours = hoursLogs
            .filter(log => log.projectId === project.id)
            .reduce((total, log) => total + log.hours, 0);
          
          const projectSpend = calculateProjectSpend(project.id);
          
          return { project, hours: projectHours, spend: projectSpend };
        });
        
        const { totalSpent, profit } = calculateRetainerMetrics(retainer, projectSpends);
        
        return { 
          retainer, 
          retainerProjects, 
          retainerHours: hoursUsed, 
          retainerSpend: totalSpent, 
          retainerProfit: profit,
          projectSpends 
        };
      });

      const totalProjects = clientOneOffProjects.length + retainerData.reduce((total, r) => total + r.retainerProjects.length, 0);
      const oneOffProjectsCount = clientOneOffProjects.length;
      const retainerProjectsCount = retainerData.reduce((total, r) => total + r.retainerProjects.length, 0);
      
      // Calculate total budget including one-off projects and retainers
      const oneOffProjectsBudget = clientOneOffProjects.reduce((total, p) => total + (Number(p.budget) || 0), 0);
      const retainersBudget = clientRetainers.reduce((total, r) => total + (Number(r.amountPaid) || 0), 0);
      const totalBudget = oneOffProjectsBudget + retainersBudget;
      
      const totalHours = hoursLogs.filter(log => 
        (clientOneOffProjects.some(p => p.id === log.projectId) || 
        retainerData.some(r => r.retainerProjects.some(p => p.id === log.projectId))) &&
        activeProjects.some(p => p.id === log.projectId)
      ).reduce((total, log) => total + log.hours, 0);

      const totalRetainers = clientRetainers.length;

      // Calculate total spent
      const totalSpent = clientOneOffProjects.reduce((total, project) => total + calculateProjectSpend(project.id), 0) +
        retainerData.reduce((total, { retainerSpend }) => total + retainerSpend, 0);

      return { 
        client, 
        clientOneOffProjects, 
        retainerData, 
        totalProjects, 
        oneOffProjectsCount,
        retainerProjectsCount,
        totalBudget, 
        totalHours,
        totalRetainers,
        totalSpent
      };
    });
  }, [clients, retainers, projects, associatedProjectsAndHours, clientsLoading, retainersLoading, projectsLoading, hoursLoading, usersLoading, hoursLogs, users, calculateProjectSpend, calculateProjectMetrics, calculateRetainerMetrics]);

  const filteredClientData = useMemo(() => {
    if (!clientData) return [];
    return clientData.filter(({ client }) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [clientData, searchTerm]);

  const isLoading = clientsLoading || retainersLoading || projectsLoading || usersLoading || hoursLoading;
  const hasData = clients.length > 0 && projects.length > 0 && hoursLogs.length > 0;

  const formatHours = (hours) => {
    const numHours = Number(hours);
    return isNaN(numHours) ? '0.00' : numHours.toFixed(2);
  };

  if (isLoading) {
    return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
  }

  if (!hasData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="text.secondary">
          No data available. Please check data loading.
        </Typography>
      </Box>
    );
  }

  if (users.length === 0) {
    console.warn("User data is missing. Some calculations may be inaccurate.");
  }

  if (!clientData) {
    return null;
  }

  return (
    <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
        Client Reports
      </Typography>

      {/* Add search bar */}
      <Box sx={{ mt: 4, mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by client name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {filteredClientData.map(({ client, clientOneOffProjects, retainerData, totalProjects, totalBudget, totalHours, totalRetainers, totalSpent }) => (
        <StyledAccordion
          key={client.id}
          expanded={expandedClient === client.id}
          onChange={() => setExpandedClient(expandedClient === client.id ? null : client.id)}
        >
          <StyledAccordionSummary 
            expandIcon={<ExpandMoreIcon />}
            expanded={expandedClient === client.id}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#3498db', color: '#fff', mr: 2 }}>
                  <BusinessIcon />
                </Avatar>
                <Typography variant="h6" sx={{ color: '#2c3e50' }}>{client.name}</Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ color: '#34495e' }}>
                {totalProjects} Projects | {totalRetainers} Retainers
              </Typography>
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ p: 3, backgroundColor: '#fff' }}>
            
            <Grid container spacing={3} sx={{ mb: 4, justifyContent: 'center' }}>
              {[
                { icon: <AccessTimeIcon />, label: 'Total Hours Logged', value: formatHours(totalHours), color: '#9b59b6' },
                { icon: <FolderIcon />, label: 'Total Projects', value: totalProjects, color: '#3498db' },
                { icon: <BusinessIcon />, label: 'Total Retainers', value: totalRetainers, color: '#f39c12' },
                ...(isAdmin ? [
                  { icon: <AttachMoneyIcon />, label: 'Total Paid', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalBudget), color: '#2ecc71' },
                  { icon: <AttachMoneyIcon />, label: 'Total Spent', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalSpent), color: '#e74c3c' },
                ] : []),
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={isAdmin ? 2.4 : 4} key={index}>
                  <StyledCard>
                    <Avatar sx={{ bgcolor: item.color, mb: 1 }}>{item.icon}</Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>{item.value}</Typography>
                    <Typography variant="body2" color="textSecondary">{item.label}</Typography>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            {/* One-off Projects */}
            <Box sx={{ mt: 8 }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>One-off Projects</Typography>
              <StyledTableContainer component={Paper}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Status</TableCell>
                      {isAdmin && <TableCell align="right">Budget</TableCell>}
                      <TableCell align="right">Hours Spent</TableCell>
                      {isAdmin && (
                        <>
                          <TableCell align="right">Amount Spent</TableCell>
                          <TableCell align="right">Profit</TableCell>
                        </>
                      )}
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {clientOneOffProjects.map(project => (
                      <TableRow key={project.id} hover>
                        <TableCell>{project.name}</TableCell>
                        <TableCell>
                          {isPastDue(project) ? (
                            <Chip 
                              label="Past Due"
                              size="small"
                              sx={{ 
                                backgroundColor: alpha('#e74c3c', 0.2), 
                                color: '#e74c3c'
                              }}
                            />
                          ) : project.status === 'active' ? (
                            <Chip 
                              label="Active"
                              size="small"
                              sx={{ 
                                backgroundColor: alpha('#3498db', 0.2), 
                                color: '#3498db'
                              }}
                            />
                          ) : (
                            <Tooltip title={`Completed on ${formatDate(project.updatedAt)}`} arrow>
                              <Chip 
                                label="Completed"
                                size="small"
                                sx={{ 
                                  backgroundColor: alpha('#2ecc71', 0.2), 
                                  color: '#2ecc71'
                                }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        {isAdmin && <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(project.budget)}</TableCell>}
                        <TableCell align="right">{formatHours(project.hours)}</TableCell>
                        {isAdmin && (
                          <>
                            <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(project.spend)}</TableCell>
                            <TableCell align="right" sx={{ color: project.profit >= 0 ? 'green' : 'red' }}>
                              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(project.profit)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Box>

            {/* Retainers and their Projects */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>Retainers</Typography>
            {retainerData.map(({ retainer, retainerProjects, retainerHours, retainerSpend, retainerProfit, projectSpends }) => (
              <Accordion key={retainer.id} sx={{ mb: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: alpha('#3498db', 0.1) }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', color: '#2c3e50', mr: 2 }}>
                      {retainer.retainerName}
                    </Typography>
                    {isPastDue(retainer) ? (
                      <Chip 
                        label="Past Due"
                        size="small"
                        sx={{ 
                          backgroundColor: alpha('#e74c3c', 0.2), 
                          color: '#e74c3c'
                        }}
                      />
                    ) : retainer.status === 'active' ? (
                      <Chip 
                        label="Active"
                        size="small"
                        sx={{ 
                          backgroundColor: alpha('#3498db', 0.2), 
                          color: '#3498db'
                        }}
                      />
                    ) : (
                      <Tooltip title={`Completed on ${formatDate(retainer.updatedAt)}`} arrow>
                        <Chip 
                          label="Completed"
                          size="small"
                          sx={{ 
                            backgroundColor: alpha('#2ecc71', 0.2), 
                            color: '#2ecc71'
                          }}
                        />
                      </Tooltip>
                    )}
                    <Chip 
                      label={`${retainerProjects.length} projects`}
                      size="small" 
                      sx={{ backgroundColor: alpha('#3498db', 0.2) }}
                    />
                    {isAdmin && (
                      <Chip 
                        label={`Budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(retainer.amountPaid)}`} 
                        size="small" 
                        sx={{ backgroundColor: alpha('#2ecc71', 0.2) }}
                      />
                    )}
                    <Chip 
                      label={`Hours: ${formatHours(retainerHours)}/${retainer.hoursPurchased}`} 
                      size="small" 
                      sx={{ backgroundColor: alpha('#9b59b6', 0.2) }}
                    />
                    {isAdmin && (
                      <>
                        <Chip 
                          label={`Spent: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(retainerSpend)}`} 
                          size="small" 
                          sx={{ backgroundColor: alpha('#e74c3c', 0.2) }}
                        />
                        <Chip 
                          label={`Profit: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(retainerProfit)}`} 
                          size="small" 
                          sx={{ backgroundColor: retainerProfit >= 0 ? alpha('#27ae60', 0.2) : alpha('#c0392b', 0.2), color: retainerProfit >= 0 ? '#27ae60' : '#c0392b' }}
                        />
                      </>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledTableContainer component={Paper}>
                    <Table>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Hours Spent</TableCell>
                          {isAdmin && <TableCell>Amount Spent</TableCell>}
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {projectSpends.map(({ project, hours, spend }) => (
                          <TableRow key={project.id} hover>
                            <TableCell>{project.name}</TableCell>
                            <TableCell>
                              {isPastDue(project) ? (
                                <Chip 
                                  label="Past Due"
                                  size="small"
                                  sx={{ 
                                    backgroundColor: alpha('#e74c3c', 0.2), 
                                    color: '#e74c3c'
                                  }}
                                />
                              ) : project.status === 'active' ? (
                                <Chip 
                                  label="Active"
                                  size="small"
                                  sx={{ 
                                    backgroundColor: alpha('#3498db', 0.2), 
                                    color: '#3498db'
                                  }}
                                />
                              ) : (
                                <Tooltip title={`Completed on ${formatDate(project.updatedAt)}`} arrow>
                                  <Chip 
                                    label="Completed"
                                    size="small"
                                    sx={{ 
                                      backgroundColor: alpha('#2ecc71', 0.2), 
                                      color: '#2ecc71'
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell>{formatHours(hours)}</TableCell>
                            {isAdmin && <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(spend)}</TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default ClientReports;