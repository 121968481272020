import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, InputLabel, TextField, Chip, Tooltip, LinearProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useClients } from '../contexts/ClientContext';
import { useProjects } from '../contexts/ProjectContext';
import { useRetainers } from '../contexts/RetainerContext';
import { useUser } from '../contexts/UserContext';
import { useHours } from '../contexts/HoursContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import moment from 'moment-timezone';
import DoneIcon from '@mui/icons-material/Done';
import { alpha } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Dashboard = () => {
  const { user } = useUser();
  const { clients, loading: clientsLoading } = useClients();
  const { projects, loading: projectsLoading } = useProjects();
  const { retainers, loading: retainersLoading, associatedProjectsAndHours } = useRetainers();
  const { users, loading: usersLoading } = useUser();
  const { hoursLogs, loading: hoursLoading } = useHours();

  const [totalSpent, setTotalSpent] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);
  const [activeRetainers, setActiveRetainers] = useState(0);
  const [totalHoursLogged, setTotalHoursLogged] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(() => {
    const now = dayjs().utc();
    return now.startOf('week').add(1, 'day'); // This will set Monday as the start of the week
  });
  const [resourceUtilization, setResourceUtilization] = useState([]);
  const [activeProjectBreakdown, setActiveProjectBreakdown] = useState([]);
  const [completedProjects, setCompletedProjects] = useState(0);
  const [retainerBreakdown, setRetainerBreakdown] = useState([]);

  const weekOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < 12; i++) {
      const weekStart = dayjs().utc().subtract(i, 'week').startOf('week').add(1, 'day');
      options.push({
        value: weekStart.format('YYYY-MM-DD'),
        label: `Week of ${weekStart.format('MMM D, YYYY')}`,
      });
    }
    return options;
  }, []);

  const weekDays = useMemo(() => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(selectedWeek.add(i, 'day').format('ddd, MMM D'));
    }
    return days;
  }, [selectedWeek]);

  useEffect(() => {
    if (!projectsLoading && !retainersLoading && !hoursLoading && !usersLoading && !clientsLoading) {
      const { startDate, endDate } = getDateRange(selectedWeek);
      
      // Filter out deleted projects
      const relevantProjects = projects.filter(project => !project.isDeleted);
      
      // Filter out deleted hours logs and those related to deleted projects
      const filteredHoursLogs = hoursLogs.filter(log => 
        dayjs(log.date).isBetween(startDate, endDate, null, '[]')
      ).filter(log => !log.isDeleted && relevantProjects.some(project => project.id === log.projectId));

      // Calculate active projects based on logged hours
      const projectsWithHours = new Set(filteredHoursLogs.map(log => log.projectId));
      const activeProjectsCount = projectsWithHours.size;
      setActiveProjects(activeProjectsCount);

      // Calculate active retainers based on logged hours
      const retainersWithHours = new Set(
        filteredHoursLogs
          .filter(log => {
            const project = relevantProjects.find(p => p.id === log.projectId);
            return project && project.retainerId;
          })
          .map(log => {
            const project = relevantProjects.find(p => p.id === log.projectId);
            return project.retainerId;
          })
      );
      const activeRetainersCount = retainersWithHours.size;
      setActiveRetainers(activeRetainersCount);

      const spent = calculateSpent(filteredHoursLogs, relevantProjects, users);
      setTotalSpent(spent);

      const totalHours = calculateTotalHours(filteredHoursLogs);
      setTotalHoursLogged(totalHours);

      // Calculate completed projects (changed from active to inactive)
      const completed = relevantProjects.filter(project => 
        project.status === 'inactive' && 
        dayjs(project.updatedAt).isBetween(startDate, endDate, null, '[]')
      ).length;
      setCompletedProjects(completed);

      // Calculate resource utilization
      const utilization = calculateResourceUtilization(users, filteredHoursLogs, relevantProjects, selectedWeek);
      setResourceUtilization(utilization);

      // Calculate active project breakdown
      const projectBreakdown = calculateActiveProjectBreakdown(filteredHoursLogs, relevantProjects, selectedWeek, clients, retainers);
      setActiveProjectBreakdown(projectBreakdown);

      // Calculate retainer data
      const activeRetainers = retainers.filter(retainer => retainer.status === 'active');
      const retainerData = activeRetainers.map(retainer => {
        const { hoursUsed: totalHoursUsed } = associatedProjectsAndHours[retainer.id] || { hoursUsed: 0 };
        const client = clients.find(client => client.id === retainer.clientId);
        const clientName = client ? client.name : 'Unknown Client';
        const retainerProjects = relevantProjects.filter(project => project.retainerId === retainer.id);
        
        // Filter hours logs for the selected week and non-deleted logs
        const filteredHoursLogs = hoursLogs.filter(log => 
          !log.isDeleted &&
          retainerProjects.some(p => p.id === log.projectId) &&
          dayjs(log.date).isBetween(startDate, endDate, null, '[]')
        );

        const weekHoursUsed = filteredHoursLogs.reduce((sum, log) => sum + log.hours, 0);
        const totalSpent = calculateSpent(filteredHoursLogs, retainerProjects, users);
        
        // Count active projects (projects with hours logged in the selected week)
        const activeProjectsCount = new Set(filteredHoursLogs.map(log => log.projectId)).size;

        return {
          retainer,
          clientName,
          totalHoursUsed,
          weekHoursUsed,
          totalSpent,
          projects: retainerProjects,
          activeProjectsCount
        };
      }).filter(data => data.weekHoursUsed > 0);

      setRetainerBreakdown(retainerData);
    }
  }, [projects, retainers, hoursLogs, users, clients, selectedWeek, projectsLoading, retainersLoading, hoursLoading, usersLoading, clientsLoading, associatedProjectsAndHours]);

  const isLoading = clientsLoading || projectsLoading || retainersLoading || usersLoading || hoursLoading;

  // Check if the user is a manager
  const isManager = user.permission === 'manager';

  if (user.permission !== 'admin' && user.permission !== 'manager') {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
  }

  const formatDisplayDate = (dateString) => {
    if (!dateString) return 'Not set';
    return parseAsUTC(dateString).format('dddd, MMM D, YYYY');
  };

  const formatInputDate = (dateString) => {
    if (!dateString) return '';
    return parseAsUTC(dateString).format('YYYY-MM-DD');
  };

  const parseAsUTC = (dateString) => {
    return moment.utc(dateString).utc(true);
  };

  const parseInputDate = (dateString) => {
    return dateString ? moment.utc(dateString).utc(true).format() : null;
  };

  const calculateActiveProjectBreakdown = (hoursLogs, projects, selectedWeek, clients, retainers) => {
    const weekStart = selectedWeek.startOf('week').toDate();
    const weekEnd = selectedWeek.endOf('week').toDate();

    return projects.map(project => {
      const projectLogs = hoursLogs.filter(log => 
        log.projectId === project.id &&
        new Date(log.date) >= weekStart && 
        new Date(log.date) <= weekEnd
      );

      const dailyHours = {
        Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0
      };
      const dailySpend = {
        Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0
      };

      projectLogs.forEach(log => {
        const day = new Date(log.date).toLocaleString('en-US', { weekday: 'short' });
        dailyHours[day] += log.hours;
        const user = users.find(u => u.id === log.creativeId);
        dailySpend[day] += log.hours * (user ? user.rate : 0);
      });

      const totalHours = Object.values(dailyHours).reduce((sum, hours) => sum + hours, 0);
      const totalSpend = Object.values(dailySpend).reduce((sum, spend) => sum + spend, 0);
      const client = clients.find(c => c.id === project.clientId);
      let retainerName = '';
      let budget = project.budget || 0;

      if (project.retainerId) {
        const retainer = retainers.find(r => r.id === project.retainerId);
        if (retainer) {
          retainerName = retainer.retainerName;
          budget = retainer.amountPaid;
        }
      }

      return {
        projectId: project.id,
        projectName: project.name,
        projectType: project.retainerId ? 'retainer' : 'one-off',
        projectStatus: project.status,
        clientName: client ? client.name : 'Unknown Client',
        retainerName,
        dailyHours,
        dailySpend,
        totalHours,
        totalSpend,
        budget
      };
    });
  };

  const calculateResourceUtilization = (users, hoursLogs, projects, selectedWeek) => {
    const weekStart = selectedWeek.startOf('week').add(1, 'day').toDate();
    const weekEnd = selectedWeek.endOf('week').add(1, 'day').toDate();

    return users.map(user => {
      const userLogs = hoursLogs.filter(log => 
        log.creativeId === user.id && 
        new Date(log.date) >= weekStart && 
        new Date(log.date) < weekEnd &&  // Use < instead of <= to exclude the next Monday
        projects.some(project => project.id === log.projectId)
      );

      const dailyHours = {
        Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0
      };

      userLogs.forEach(log => {
        const day = new Date(log.date).toLocaleString('en-US', { weekday: 'short' });
        dailyHours[day] += log.hours;
      });

      const totalHours = Object.values(dailyHours).reduce((sum, hours) => sum + hours, 0);
      const cost = totalHours * user.rate;

      return {
        id: user.id,
        name: user.name,
        role: user.role || 'N/A',
        dailyHours,
        totalHours,
        cost
      };
    }).sort((a, b) => b.cost - a.cost);
  };

  const formatCompletionDate = (date) => {
    return dayjs(date).format('ddd, MMM D, YYYY');
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handlePreviousWeek = () => {
    setSelectedWeek(selectedWeek.subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    if (!isCurrentWeek) {
      setSelectedWeek(selectedWeek.add(1, 'week'));
    }
  };

  const isCurrentWeek = selectedWeek.isSame(dayjs().startOf('week'), 'week');

  return (
    <>
      <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>
          {isManager ? 'Manager Dashboard' : 'Admin Dashboard'}
        </Typography>

        {/* Week selection */}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton onClick={handlePreviousWeek} sx={{ mr: 2 }}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="week-select-label">Select Week</InputLabel>
            <Select
              labelId="week-select-label"
              value={selectedWeek.format('YYYY-MM-DD')}
              onChange={(e) => {
                const newWeek = dayjs(e.target.value).utc().startOf('week');
                console.log('Selected week start:', newWeek.format('YYYY-MM-DD'));
                setSelectedWeek(newWeek);
              }}
              label="Select Week"
            >
              {weekOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton 
            onClick={handleNextWeek} 
            sx={{ 
              ml: 2, 
              opacity: isCurrentWeek ? 0.5 : 1,
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            }}
            disabled={isCurrentWeek}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        {/* Dashboard cards */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={isManager ? 3 : 2.4}>
            <StyledPaper>
              <Typography variant="h6" sx={{ color: '#3498db', mb: 1 }}>Total Hours Logged</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                {totalHoursLogged.toFixed(2)}
              </Typography>
            </StyledPaper>
          </Grid>
          {!isManager && (
            <Grid item xs={12} sm={6} md={2.4}>
              <StyledPaper>
                <Typography variant="h6" sx={{ color: '#e74c3c', mb: 1 }}>Total Spent</Typography>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                  {formatCurrency(totalSpent)}
                </Typography>
              </StyledPaper>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={isManager ? 3 : 2.4}>
            <StyledPaper>
              <Typography variant="h6" sx={{ color: '#2ecc71', mb: 1 }}>Active Projects</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                {activeProjects}
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={isManager ? 3 : 2.4}>
            <StyledPaper>
              <Typography variant="h6" sx={{ color: '#f39c12', mb: 1 }}>Active Retainers</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                {activeRetainers}
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={isManager ? 3 : 2.4}>
            <StyledPaper>
              <Typography variant="h6" sx={{ color: '#9b59b6', mb: 1 }}>Completed Projects</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                {completedProjects}
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>

        {/* Resource Utilization */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>
          Resource Utilization
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <StyledTableHead>
              <TableRow>
                <TableCell>Employee</TableCell>
                {weekDays.map((day) => (
                  <TableCell key={day} align="right">
                    {day}
                  </TableCell>
                ))}
                <TableCell align="right">Total Hours</TableCell>
                {!isManager && <TableCell align="right">Cost</TableCell>}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {resourceUtilization.map((employee) => (
                <StyledTableRow key={employee.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>{employee.name}</Typography>
                      <Typography variant="body2" color="textSecondary">{employee.role}</Typography>
                    </Box>
                  </TableCell>
                  {weekDays.map((day) => (
                    <TableCell key={day} align="right">
                      {employee.dailyHours[day.slice(0, 3)].toFixed(2)}
                    </TableCell>
                  ))}
                  <TableCell align="right">{employee.totalHours.toFixed(2)}</TableCell>
                  {!isManager && <TableCell align="right">{formatCurrency(employee.cost)}</TableCell>}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>

        {/* Project Breakdown */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>
          Project Breakdown (Active and Inactive)
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <StyledTableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                {weekDays.map((day) => (
                  <TableCell key={day} align="right">
                    {day}
                  </TableCell>
                ))}
                <TableCell align="right">Total Hours</TableCell>
                {!isManager && <TableCell align="right">Total Spend / Budget</TableCell>}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {activeProjectBreakdown
                .filter(project => project.totalHours > 0)
                .map((project) => (
                  <StyledTableRow key={project.projectId}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>{project.projectName}</Typography>
                        <Chip
                          label={
                            project.projectType === 'retainer'
                              ? `${project.retainerName} - ${project.clientName}`
                              : project.clientName
                          }
                          size="small"
                          variant="outlined"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                        <Chip
                          label={project.projectType}
                          size="small"
                          color={project.projectType === 'retainer' ? 'primary' : 'secondary'}
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                        {project.projectStatus === 'inactive' && (
                          <Chip
                            icon={<DoneIcon />}
                            label={`Completed on ${formatCompletionDate(project.updatedAt)}`}
                            size="small"
                            color="success"
                            sx={{ mb: 0.5 }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    {weekDays.map((day) => (
                      <TableCell key={day} align="right">
                        {project.dailyHours[day.slice(0, 3)].toFixed(2)}
                      </TableCell>
                    ))}
                    <TableCell align="right">
                      {project.totalHours.toFixed(2)}
                    </TableCell>
                    {!isManager && (
                      <TableCell align="right">
                        {formatCurrency(project.totalSpend)} / {formatCurrency(project.budget)}
                        <Typography variant="caption" display="block" color="textSecondary">
                          {((project.totalSpend / project.budget) * 100).toFixed(1)}%
                        </Typography>
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>

        {/* Retainer Breakdown (for both admins and managers) */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>
          Retainer Breakdown
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <StyledTableHead>
              <TableRow>
                <TableCell>Retainer</TableCell>
                <TableCell>Client</TableCell>
                <TableCell align="right">Active Projects</TableCell>
                <TableCell align="right">Hours Used (Week/Total)</TableCell>
                {!isManager && <TableCell align="right">Budget Used</TableCell>}
                <TableCell>Progress</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {retainerBreakdown.map((data) => (
                <StyledTableRow key={data.retainer.id}>
                  <TableCell>{data.retainer.retainerName}</TableCell>
                  <TableCell>{data.clientName}</TableCell>
                  <TableCell align="right">{data.activeProjectsCount}</TableCell>
                  <TableCell align="right">
                    {data.weekHoursUsed.toFixed(2)} / {data.totalHoursUsed.toFixed(2)} / {data.retainer.hoursPurchased}
                  </TableCell>
                  {!isManager && (
                    <TableCell align="right">
                      {formatCurrency(data.totalSpent)} / {formatCurrency(data.retainer.amountPaid)}
                    </TableCell>
                  )}
                  <TableCell sx={{ width: '30%' }}>
                    <Tooltip title={`Week Hours: ${data.weekHoursUsed.toFixed(2)}, Total Hours: ${data.totalHoursUsed.toFixed(2)}, Remaining: ${Math.max(0, data.retainer.hoursPurchased - data.totalHoursUsed).toFixed(2)}`} arrow>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1, position: 'relative', height: 10, borderRadius: 5, bgcolor: '#e0e0e0', overflow: 'hidden' }}>
                          <Box sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            bgcolor: '#2980b9',
                            width: `${Math.min(100, (data.weekHoursUsed / data.retainer.hoursPurchased) * 100)}%`,
                            borderRadius: 5,
                          }} />
                          <Box sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            bgcolor: '#3498db',
                            width: `${Math.min(100, (data.totalHoursUsed / data.retainer.hoursPurchased) * 100)}%`,
                            borderRadius: 5,
                          }} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" color="text.secondary">
                            {`${Math.min(100, (data.totalHoursUsed / data.retainer.hoursPurchased * 100)).toFixed(0)}%`}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </>
  );
};

const getDateRange = (selectedWeek) => {
  const startDate = selectedWeek.startOf('week').add(1, 'day');
  const endDate = startDate.add(6, 'days').endOf('day');
  return { startDate, endDate };
};

const filterByDateRange = (items, startDate, endDate, dateField) => {
  return items.filter(item => {
    const itemDate = dayjs(item[dateField]);
    return !item.isDeleted && itemDate.isBetween(startDate, endDate, null, '[]');
  });
};

const calculateSpent = (hoursLogs, projects, users) => {
  return hoursLogs.reduce((sum, log) => {
    const project = projects.find(p => p.id === log.projectId);
    if (project) {
      const user = users.find(u => u.id === log.creativeId);
      return sum + (log.hours * (user ? user.rate : 0));
    }
    return sum;
  }, 0);
};

const calculateTotalHours = (hoursLogs) => {
  return hoursLogs.reduce((sum, log) => sum + (Number(log.hours) || 0), 0);
};

export default Dashboard;